import React, { Component } from 'react';

class Zdarzenia extends Component {
  componentDidMount() {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    scriptElement.innerHTML = `
      parents = document.getElementsByClassName("actum-szr-events-element");
    `;
    document.body.appendChild(scriptElement);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      parents = document.getElementsByClassName("actum-szr-events-element");
      rows = parents.length/4;
      for (let i = 0; i < rows; i++) {
        if (i % 2 === 0) {
          for(let j = 0; j < 4; j++) {
            const elem = parents[i*4 + j];
            elem.classList.add("actum-szr-events-element-nth");
          }
        }
      }
    `;
    document.body.appendChild(script);
  }

  render() {
    return (
      <div className="actum-szr-events-content">
        <div className="actum-szr-events-title">Czas</div>
        <div className="actum-szr-events-title">Data</div>
        <div className="actum-szr-events-title">Stan</div>
        <div className="actum-szr-events-title">Treść</div>

        <span className="actum-szr-clicked">12:52:34 PM</span>
        <span className="actum-szr-clicked">6/5/2018</span>
        <span className="actum-szr-clicked">I</span>
        <span className="actum-szr-clicked">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>


        <span className="actum-szr-events-element">3:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">6/5/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">3:43:06 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:27 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:26 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:25 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:24 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>


        <span className="actum-szr-events-element">3:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">6/5/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">3:43:06 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:27 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:26 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:25 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:24 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>


        <span className="actum-szr-events-element">3:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">6/5/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">3:43:06 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:27 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:26 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:25 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:24 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>


        <span className="actum-szr-events-element">3:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">6/5/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">3:43:06 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:27 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:26 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:25 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:24 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>


        <span className="actum-szr-events-element">3:43:18 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">6/5/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">3:43:06 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">3:43:08 AM</span>
        <span className="actum-szr-events-element">4/25/2018</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:27 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:06:26 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:25 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:06:24 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Wyzwolony</span>

        <span className="actum-szr-events-element">1:00:23 PM</span>
        <span className="actum-szr-events-element">10/24/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:17 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>

        <span className="actum-szr-events-element">1:16:08 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">1:05:46 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">I</span>
        <span className="actum-szr-events-element">Brak komunikacji z 2F1 - JVA</span>

        <span className="actum-szr-events-element">12:36:24 PM</span>
        <span className="actum-szr-events-element">10/17/2017</span>
        <span className="actum-szr-events-element">(I)O</span>
        <span className="actum-szr-events-element">2F1 Załączony</span>
      </div>
    );
  }
}

export default Zdarzenia;
