import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Dashboard extends Component {
  render() {
    return (
      <div className="actum-szr-content actum-szr-content-small">
        <div className="actum-szr-content-rows">
          <Link to="elewacja-main" className="actum-szr-content-button actum-szr-gradient">
            <img src="assets/siemens-Asset_9.svg" alt="home" className="actum-szr-menu-icon" />
                        ELEWACJA ROZDZIELNICY
          </Link>
          <Link to="schemat-main" className="actum-szr-content-button actum-szr-gradient">
            <img src="assets/siemens-Asset_8.svg" alt="home" className="actum-szr-menu-icon" />
                        SCHEMAT ZASILANIA
          </Link>
          <Link to="schema-szr-main" className="actum-szr-content-button actum-szr-gradient">
            <img src="assets/siemens-Asset_7.svg" alt="home" className="actum-szr-menu-icon" />
                        UKŁAD SZR
          </Link>
          <Link to="zdarzenia-main" className="actum-szr-content-button actum-szr-gradient">
            <img src="assets/siemens-mail.svg" alt="home" className="actum-szr-menu-icon" />
                        DZIENNIK ZDARZEŃ
          </Link>
          <Link to="diagnostyka-main" className="actum-szr-content-button actum-szr-gradient">
            <img src="assets/siemens-Asset_16.svg" alt="home" className="actum-szr-menu-icon" />
                        DIAGNOSTYKA SYSTEMOWA
          </Link>
          <Link to="dokumentacja-main" className="actum-szr-content-button actum-szr-gradient">
            <img src="assets/siemens-docs.svg" alt="home" className="actum-szr-menu-icon" />
                        DOKUMENT. TECHNICZNA
          </Link>
          <Link to="siec-main" className="actum-szr-content-button actum-szr-gradient">
            <img src="assets/siemens-graph.svg" alt="home" className="actum-szr-menu-icon" />
                        SIEĆ KOMUNIKAC.
          </Link>
        </div>
      </div>
    );
  }
}

export default Dashboard;
