import React, { Component } from 'react';
import './App.css';
import './styles/style.css';
import './styles/parametry.css';
import Layout from './Layout';
import MainRoutes from './routes';

class App extends Component {

  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      let parents;
      let rows;
    `;
    document.body.appendChild(script);
  }

  render() {
    return (
      <div className="App">
        <Layout>
          <MainRoutes />
        </Layout>
      </div>
    );
  }
}

export default App;
