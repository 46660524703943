import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SecondParams from './secondParams';
import Parametry from './parametry';
import ParametryGen from './parametry-gen';

class Elewacja extends Component {
  render() {
    return (
        <>
          <div className="actum-szr-params-container">
            <Switch>
              <Route
                  path="/elewacja/second-params"
                  component={SecondParams}
              />
              <Route
                  path="/elewacja/parametry"
                  component={Parametry}
              />
              <Route
                  path="/siec/parametry"
                  component={Parametry}
              />
              <Route
                  path="/schemat/second-params"
                  component={SecondParams}
              />
              <Route
                  path="/siec/second-params"
                  component={SecondParams}
              />
              <Route
                  path="/schemat/parametry"
                  component={Parametry}
              />
              <Route
                  path="/elewacja/parametry-gen"
                  component={ParametryGen}
              />
              <Route
                  path="/schemat/parametry-gen"
                  component={ParametryGen}
              />
              <Route
                  path="/siec/parametry-gen"
                  component={ParametryGen}
              />
              <Redirect to="/dashboard" />
            </Switch>
          </div>
        </>
    );
  }
}

export default Elewacja;
