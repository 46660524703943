import React from 'react';
import classNames from 'classnames';
import '../../../styles/wykres.css';
import { Link } from 'react-router-dom';
import { swapDataGraph } from '../../../dataSwapperGraph';
import WykresYAxis from '../../../assets/WykresYAxis';
import {Czynna, Prad} from "../../../assets";

class SecondParams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 'parametry',
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      persistUrlParameters();
      secondParamsSwapData(getUrlParam('elementName'));
      insertSwitchNames();
    `;
    document.body.appendChild(script);
  }

  componentDidUpdate() {
    const { content } = this.state;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    switch (content) {
      default:
        script.innerHTML = `
          persistUrlParameters();
          insertSwitchNames();
        `;
        break;
      case 'aparat':
        script.innerHTML = `
          persistUrlParameters();
          insertSwitchNames();
        `;
        break;
      case 'mocCzynna':
        script.innerHTML = `
          persistUrlParameters();
          insertSwitchNames();
        `;
        swapDataGraph(14, 5, 0);
        break;
      case 'prad':
        script.innerHTML = `
          persistUrlParameters();
          insertSwitchNames();
        `;
        swapDataGraph();
        break;
      case 'mocBierna':
        script.innerHTML = `
          persistUrlParameters();
          insertSwitchNames();
        `;
        swapDataGraph(14, 5, 15);
        break;
      case 'zdarzenia':
        script.innerHTML = `
          persistUrlParameters();
          insertSwitchNames();
          parents = document.getElementsByClassName("actum-szr-events-element");
          rows = parents.length/4;
          for (let i = 0; i < rows; i++) {
            if (i % 2 === 0) {
              for(let j = 0; j < 4; j++) {
                const elem = parents[i*4 + j];
                elem.classList.add("actum-szr-events-element-nth");
              }
            }
          }
        `;
        break;
    }
    document.body.appendChild(script);
  }

    setContent = (content) => {
      this.setState({ content });
    };

    render() {
      const { content } = this.state;
      const menu = (
        <div id="update" className="actum-szr-sidebar actum-szr-content-tall">
          <div className="actum-szr-sidebar-rows">
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setContent('parametry')}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': content === 'parametry',
                'actum-szr-param-button actum-szr-gradient': content !== 'parametry',
              })}
            >
                        Parametry
                        odb.
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setContent('aparat')}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': content === 'aparat',
                'actum-szr-param-button actum-szr-gradient': content !== 'aparat',
              })}
            >
                        Aparat
                        zabezp.
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setContent('mocCzynna')}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': content === 'mocCzynna',
                'actum-szr-param-button actum-szr-gradient': content !== 'mocCzynna',
              })}
            >
                        Moc czynna
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setContent('mocBierna')}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': content === 'mocBierna',
                'actum-szr-param-button actum-szr-gradient': content !== 'mocBierna',
              })}
            >
                        Moc bierna
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setContent('prad')}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': content === 'prad',
                'actum-szr-param-button actum-szr-gradient': content !== 'prad',
              })}
            >
                        Prąd
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setContent('zdarzenia')}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': content === 'zdarzenia',
                'actum-szr-param-button actum-szr-gradient': content !== 'zdarzenia',
              })}
            >
                        Zdarzenia
            </div>
          </div>
          <div className="actum-szr-sidebar-bottom-buttons">
            <Link to="/dashboard" className="actum-szr-param-bottom actum-szr-gradient">
              <img src="../assets/siemens-home.svg" alt="home" className="actum-szr-footer-icon" />
            </Link>
            <Link
              to={`/${this.props.history.location.pathname.split('/')[1]}-main`}
              role="button"
              tabIndex={0}
              className="actum-szr-param-bottom actum-szr-gradient"
            >
              <img src="../assets/siemens-Asset_10.svg" alt="home" className="actum-szr-footer-icon" />
            </Link>
          </div>
        </div>
      );
      switch (content) {
        default:
        case 'parametry':
          return (
            <>
              {menu}
              <div className="actum-szr-content actum-szr-content-tall">
                <div className="actum-szr-second-content-rows">
                  <div className="actum-szr-title">
                                    Wyłącznik&nbsp;
                    <span className="switchName">2FS</span>
                  </div>
                  <div className="actum-szr-title">Status wyłącznika</div>
                  <div className="actum-szr-title">Ostatnie wyzwolenie</div>
                  <div id="actum-szr-switch" className="actum-szr-zas-content">
                    <span className="actum-szr-zas-content__transformer-name">TR1</span>
                    <span className="actum-szr-zas-content__switch-name">2F2</span>
                    <span className="actum-szr-zas-content__location-name">B2 RG</span>
                  </div>
                  <div className="actum-szr-status">
                    <div className="actum-szr-button-container">
                      <div id="closed" className="actum-szr-indicator-button" />
                                        Załączony
                      <div />
                    </div>
                    <div className="actum-szr-button-container">
                      <div id="open" className="actum-szr-indicator-button" />
                                        Wyłączony
                      <div />
                    </div>
                    <div className="actum-szr-button-container">
                      <div className="actum-szr-indicator-button actum-szr-red-gradient" />
                                        Wyzwolony
                      <div />
                    </div>
                    <div className="actum-szr-button-container">
                      <div className="actum-szr-indicator-button actum-szr-red-gradient" />
                                        Przeciążenie
                      <div />
                    </div>
                  </div>
                  <div className="actum-szr-wyzw">
                    <label className="actum-szr-nap-label">Przyczyna ost. wyzwolenia</label>
                    <div className="actum-szr-reason-container">
                      <div className="actum-szr-accept-button actum-szr-gradient">Potwierdź</div>
                      <input
                        type="text"
                        placeholder="Zwarcie bezwłoczne (I)"
                        className="actum-szr-param-input actum-szr-reason"
                      />
                    </div>
                    <label className="actum-szr-nap-label">Data ost. wyzwolenia</label>
                    <input
                      type="text"
                      placeholder="10/24/2017 1:06:26 PM"
                      className="actum-szr-param-input actum-szr-date-input"
                    />
                  </div>
                  <div className="actum-szr-wsp-title">Komunikacja PROFINET</div>
                  <div className="actum-szr-communication">
                    <div className="actum-szr-button-container">
                      <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                        Komunikacja
                      <div />
                    </div>
                    <div className="actum-szr-button-container">
                      <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                        Błąd urządzenia
                      <div />
                    </div>
                  </div>
                  <div className="actum-szr-pr-title">Prąd</div>
                  <div className="actum-szr-pr">
                    <label className="actum-szr-nap-label">Prąd L1</label>
                    <input type="text" name="pr" placeholder="" className="actum-szr-param-input L1" />
                    <label className="actum-szr-nap-label">Prąd L2</label>
                    <input type="text" name="pr" placeholder="" className="actum-szr-param-input L2" />
                    <label className="actum-szr-nap-label">Prąd L3</label>
                    <input
                      type="text"
                      name="pr"
                      placeholder=""
                      className="actum-szr-param-input L3"
                    />
                  </div>
                  <div className="actum-szr-thdnap-title">Moc</div>
                  <div className="actum-szr-thdnap">
                    <label className="actum-szr-nap-label">Moc czynna</label>
                    <input
                      type="text"
                      name="mc"
                      placeholder=""
                      className="actum-szr-param-input activePower"
                    />
                    <label className="actum-szr-nap-label ">Moc bierna</label>
                    <input
                      type="text"
                      name="mb"
                      placeholder=""
                      className="actum-szr-param-input reactivePower"
                    />
                    <label className="actum-szr-nap-label ">Moc pozorna</label>
                    <input
                      type="text"
                      name="mp"
                      placeholder=""
                      className="actum-szr-param-input apparentPower"
                    />
                    <label className="actum-szr-nap-label ">Cos Total</label>
                    <input
                      type="text"
                      name="total"
                      placeholder=""
                      className="actum-szr-param-input cosTotal"
                    />
                  </div>
                  <div className="actum-szr-footer" />
                </div>
              </div>
            </>
          );
        case 'aparat':
          return (
            <>
              {menu}
              <div className="actum-szr-content actum-szr-content-tall">
                <div className="actum-szr-aparat-rows">
                  <div className="actum-szr-title">
                                    Wyłącznik&nbsp;
                    <span className="switchName">2FS</span>
                  </div>
                  <div className="actum-szr-title">Statystyka wyłącznika</div>

                  <div id="actum-szr-switch" className="actum-szr-zas-content">
                    <span className="actum-szr-zas-content__transformer-name">TR1</span>
                    <span className="actum-szr-zas-content__switch-name">2F2</span>
                    <span className="actum-szr-zas-content__location-name">B2 RG</span>
                  </div>

                  <div className="actum-szr-stats">
                    <div className="actum-szr-stats-subsection">
                      <label className="actum-szr-nap-label">Liczba załączeń</label>
                      <div className="actum-szr-reason-container">
                        <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                        <input type="text" placeholder="12" className="actum-szr-param-input actum-szr-reason" />
                      </div>
                      <label className="actum-szr-nap-label">Ostatnie załączenie</label>
                      <input
                        type="text"
                        placeholder="12:10:31 05/08/2019"
                        className="actum-szr-param-input actum-szr-reason"
                      />
                    </div>
                    <div className="actum-szr-stats-subsection">
                      <label className="actum-szr-nap-label">Liczba wyłączeń</label>
                      <div className="actum-szr-reason-container">
                        <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                        <input type="text" placeholder="36" className="actum-szr-param-input actum-szr-reason" />
                      </div>
                      <label className="actum-szr-nap-label">Ostatnie wyłączenie</label>
                      <input
                        type="text"
                        placeholder="12:10:31 05/08/2019"
                        className="actum-szr-param-input actum-szr-reason"
                      />
                    </div>
                    <div className="actum-szr-stats-subsection">
                      <label className="actum-szr-nap-label">Liczba wyzwoleń</label>
                      <div className="actum-szr-reason-container">
                        <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                        <input type="text" placeholder="12" className="actum-szr-param-input actum-szr-reason" />
                      </div>
                      <label className="actum-szr-nap-label">Ostatnie wyzwolenie</label>
                      <input
                        type="text"
                        placeholder="12:10:31 05/08/2019"
                        className="actum-szr-param-input actum-szr-reason"
                      />
                    </div>
                    <div className="actum-szr-stats-subsection">
                      <label className="actum-szr-nap-label">Liczba operacji łączeniowych</label>
                      <div className="actum-szr-reason-container">
                        <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                        <input type="text" placeholder="73" className="actum-szr-param-input actum-szr-reason" />
                      </div>
                      <label className="actum-szr-nap-label">Ostatnia operacja</label>
                      <input
                        type="text"
                        placeholder="12:10:31 05/08/2019"
                        className="actum-szr-param-input actum-szr-reason"
                      />
                    </div>

                  </div>
                </div>
              </div>
            </>
          );
        case 'mocCzynna':
          return (
            <>
              {menu}
              <div className="actum-szr-content actum-szr-content-tall">
                <div className="actum-szr-chart-data-wykres">
                  <div className="actum-szr-first-title-wykres">
                    <span className="switchName">2FS</span>
                                    &nbsp;- Moc
                                    czynna chwilowa
                  </div>
                  <div className="actum-szr-chart-wykres">
                    <WykresYAxis
                      id="yAxis"
                      className="actum-szr-y-axis"
                      height="100%"
                    />
                    <div className="actum-szr-chart-wrapper-wykres">
                      <Czynna
                        id="czynna"
                        className="actum-szr-czynna-svg"
                      />
                    </div>
                  </div>
                  <div className="actum-szr-second-title-wykres">Wartości maksymalne / minimalne</div>
                  <div className="actum-szr-data">
                    <div className="actum-szr-data-text-left">Maks. moc czynna</div>
                    <div className="actum-szr-input-container">
                      <input
                        id="max-active"
                        type="text"
                        placeholder="00061.1kW"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-data-text">Data</div>
                    <div className="actum-szr-input-container">
                      <input
                        type="text"
                        placeholder="12/18/2017 1:22:34 PM"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-input-container">
                      <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                    </div>

                    <div className="actum-szr-data-text-left">Min. moc czynna</div>
                    <div className="actum-szr-input-container">
                      <input
                        id="min-active"
                        type="text"
                        placeholder="00000.0kW"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-data-text">Data</div>
                    <div className="actum-szr-input-container">
                      <input
                        type="text"
                        placeholder="10/22/2017 7:58:11 AM"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-input-container">
                      <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        case 'mocBierna':
          return (
            <>
              {menu}
              <div className="actum-szr-content actum-szr-content-tall">
                <div className="actum-szr-chart-data-wykres">
                  <div className="actum-szr-first-title-wykres">
                    <span className="switchName">2FS</span>
                                    &nbsp;-
                                    Moc bierna chwilowa
                  </div>
                  <div className="actum-szr-chart-wykres">
                    <WykresYAxis
                      height="100%"
                      id="yAxis"
                      className="actum-szr-y-axis"
                    />
                    <div className="actum-szr-chart-wrapper-wykres">
                      <Czynna
                        id="czynna"
                        className="actum-szr-czynna-svg"
                      />
                    </div>
                  </div>
                  <div className="actum-szr-second-title-wykres">Wartości maksymalne / minimalne</div>
                  <div className="actum-szr-data">
                    <div className="actum-szr-data-text-left">Maks. moc bierna</div>
                    <div className="actum-szr-input-container">
                      <input
                        id="max-reactive"
                        type="text"
                        placeholder="00043.4kvar"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-data-text">Data</div>
                    <div className="actum-szr-input-container">
                      <input
                        type="text"
                        placeholder="12/18/2017 1:22:34 PM"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-input-container">
                      <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                    </div>

                    <div className="actum-szr-data-text-left">Min. moc bierna</div>
                    <div className="actum-szr-input-container">
                      <input
                        id="min-reactive"
                        type="text"
                        placeholder="00000.0kvar"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-data-text">Data</div>
                    <div className="actum-szr-input-container">
                      <input
                        type="text"
                        placeholder="10/22/2017 7:58:11 AM"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-input-container">
                      <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        case 'prad':
          return (
            <>
              {menu}
              <div className="actum-szr-content actum-szr-content-tall">
                <div className="actum-szr-chart-data-chw">
                  <div className="actum-szr-first-title-wykres">
                    <span className="switchName">2FS</span>
                                    &nbsp;- Prąd
                                    chwilowy
                  </div>
                  <div className="actum-szr-chart-wykres">
                    <WykresYAxis
                      height="100%"
                      id="yAxis"
                      className="actum-szr-y-axis"
                    />
                    <div className="actum-szr-chart-wrapper-wykres">
                      <Prad
                        id="czynna"
                        className="actum-szr-czynna-svg"
                      />
                    </div>
                  </div>
                  <div className="actum-szr-second-title-wykres">Wartości maksymalne</div>
                  <div className="actum-szr-data-chw">
                    <div className="actum-szr-data-text-left">Maksymalny prąd</div>
                    <div className="actum-szr-input-container">
                      <input
                        id="max-current"
                        type="text"
                        placeholder="00109.2A"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-data-text">Data</div>
                    <div className="actum-szr-input-container">
                      <input
                        type="text"
                        placeholder="10/24/2017 1:00:25 PM"
                        className="actum-szr-data-input"
                      />
                    </div>
                    <div className="actum-szr-input-container">
                      <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                    </div>

                    <div className="actum-szr-button-container actum-szr-prad-text">
                      <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                        Prąd L1
                      <div />
                    </div>
                    <div className="actum-szr-button-container actum-szr-prad-text">
                      <div className="actum-szr-indicator-button actum-szr-red-gradient" />
                                        Prąd L2
                      <div />
                    </div>
                    <div className="actum-szr-button-container actum-szr-prad-text">
                      <div className="actum-szr-indicator-button actum-szr-gradient" />
                                        Prąd L3
                      <div />
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        case 'zdarzenia':
          return (
            <>
              {menu}
              <div className="actum-szr-events-content actum-szr-content-tall">
                <div className="actum-szr-events-title">Czas</div>
                <div className="actum-szr-events-title">Data</div>
                <div className="actum-szr-events-title">Stan</div>
                <div className="actum-szr-events-title">Treść</div>

                <span className="actum-szr-clicked">12:52:34 PM</span>
                <span className="actum-szr-clicked">6/5/2018</span>
                <span className="actum-szr-clicked">I</span>
                <span className="actum-szr-clicked">
                  <span className="switchName">2FS</span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:17 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:43:18 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>


                <span className="actum-szr-events-element">3:43:18 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">3:43:08 AM</span>
                <span className="actum-szr-events-element">6/5/2018</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">3:43:06 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">3:43:08 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:06:27 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:06:26 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:06:25 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:06:24 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:00:23 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:00:23 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:17 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:17 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:43:18 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>


                <span className="actum-szr-events-element">3:43:18 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">3:43:08 AM</span>
                <span className="actum-szr-events-element">6/5/2018</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">3:43:06 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">3:43:08 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:06:27 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:06:26 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:06:25 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:06:24 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:00:23 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:00:23 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:17 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:17 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:43:18 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>


                <span className="actum-szr-events-element">3:43:18 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">3:43:08 AM</span>
                <span className="actum-szr-events-element">6/5/2018</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">3:43:06 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">3:43:08 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:06:27 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:06:26 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:06:25 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:06:24 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:00:23 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:00:23 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:17 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:17 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:43:18 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>


                <span className="actum-szr-events-element">3:43:18 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">3:43:08 AM</span>
                <span className="actum-szr-events-element">6/5/2018</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">3:43:06 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">3:43:08 AM</span>
                <span className="actum-szr-events-element">4/25/2018</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:06:27 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:06:26 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:06:25 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:06:24 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:00:23 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Wyzwolony
                </span>

                <span className="actum-szr-events-element">1:00:23 PM</span>
                <span className="actum-szr-events-element">10/24/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:17 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:17 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>

                <span className="actum-szr-events-element">1:16:08 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">1:05:46 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">I</span>
                <span className="actum-szr-events-element">
Brak komunikacji z&nbsp;
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                - JVA
                </span>

                <span className="actum-szr-events-element">12:36:24 PM</span>
                <span className="actum-szr-events-element">10/17/2017</span>
                <span className="actum-szr-events-element">(I)O</span>
                <span className="actum-szr-events-element">
                  <span
                    className="switchName"
                  >
2FS
                  </span>
                  {' '}
                                Załączony
                </span>
              </div>
            </>
          );
      }
    }
}

export default SecondParams;
