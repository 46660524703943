import * as React from 'react';

const SvgSiemensPlansza1 = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 949.42 478.06"
    {...props}
  >
    <defs id="defs7033">
      <style id="style7031">
        {
          '.cls-1,.cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}.cls-1{stroke:#0f0}.cls-2{stroke:#445175}.cls-3{font-size:10px}.cls-13,.cls-3{fill:#fff}.cls-15,.cls-3{font-family:MyriadPro-Regular,Myriad Pro,sans-serif}.cls-6{letter-spacing:-.01em}.cls-12{fill:#51668b}.cls-14{letter-spacing:-.01em}.cls-15{font-size:9px}.yellow{fill:#ff0;opacity:.3}'
        }
      </style>
    </defs>
    <path
      className="cls-1"
      id="polyline7037"
      d="M612.31 115.2h-55.7V76.23H441.67v38.97"
    />
    <path className="cls-1" id="line7039" d="M514.99 76.23v115.59" />
    <path
      className="cls-1"
      id="line7041"
      fill="none"
      stroke="#0f0"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M780.247 282.495h166.258"
    />
    <path className="cls-1" id="line7043" d="M37.5 282.5v117" />
    <path className="cls-1" id="line7045" d="M95.79 282.5v117" />
    <path className="cls-1" id="line7047" d="M154.07 282.5v40.55" />
    <path className="cls-1" id="line7049" d="M212.36 92.3v230.75" />
    <path className="cls-1" id="line7051" d="M212.36 1.5v12.86" />
    <path className="cls-1" id="line7053" d="M270.64 282.5v117" />
    <path className="cls-1" id="line7055" d="M328.93 282.5v117" />
    <path
      className="cls-1"
      id="polyline7057"
      transform="matrix(1.04397 0 0 1 -17.026 0)"
      fill="none"
      stroke="#0f0"
      strokeWidth={2.936}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M387.21 323.05V173.5h-55.74v38"
    />
    <path className="cls-1" id="line7059" d="M445.5 282.5v117" />
    <path className="cls-1" id="line7061" d="M500.13 282.5v117" />
    <path className="cls-1" id="line7063" d="M556.22 282.5v116" />
    <path className="cls-1" id="line7065" d="M612.31 92.3v307.2" />
    <path className="cls-1" id="line7067" d="M612.31 1.5v12.86" />
    <path className="cls-1" id="line7069" d="M671.75 282.5v117" />
    <path className="cls-1" id="polyline7071" d="M724.5 399.5v-226h56v108" />
    <path className="cls-1" id="line7075" d="M866.5 282.5v117" />
    <path className="cls-1" id="line7077" d="M922.5 282.5v40.55" />
    <path className="cls-1" id="polyline7079" d="M917.5 282.5v-34l-20-27" />
    <path className="cls-1" id="polyline7081" d="M328.93 283.5v-35l-20-27" />
    <path
      className="cls-1"
      id="polyline7083"
      d="M212.36 115.2h-55.71V76.23H41.71v38.97"
    />
    <path className="cls-1" id="line7085" d="M115.03 76.23v115.59" />
    <path
      className="cls-1"
      id="polyline7087"
      d="M604.89 260.1l7.42 7.42 7.42-7.42"
    />
    <path
      className="cls-1"
      id="polyline7089"
      d="M604.89 268.52l7.42 7.42 7.42-7.42"
    />
    <g id="g7095">
      <path className="cls-1" id="line7091" d="M146.65 315.63l14.84 14.84" />
      <path className="cls-1" id="line7093" d="M161.49 315.63l-14.84 14.84" />
    </g>
    <g id="g7101">
      <path className="cls-1" id="line7097" d="M204.94 315.63l14.84 14.84" />
      <path className="cls-1" id="line7099" d="M219.78 315.63l-14.84 14.84" />
    </g>
    <g id="g7107">
      <path className="cls-1" id="line7103" d="M263.22 315.63l14.84 14.84" />
      <path className="cls-1" id="line7105" d="M278.06 315.63l-14.84 14.84" />
    </g>
    <g id="g7113">
      <path className="cls-1" id="line7109" d="M321.51 315.63l14.84 14.84" />
      <path className="cls-1" id="line7111" d="M336.35 315.63l-14.84 14.84" />
    </g>
    <g id="g7119" transform="translate(-2.7)">
      <path
        className="cls-1"
        id="line7115"
        fill="none"
        stroke="#0f0"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M324.05 204.5l14.84 14.84"
      />
      <path
        className="cls-1"
        id="line7117"
        fill="none"
        stroke="#0f0"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M338.89 204.5l-14.84 14.84"
      />
    </g>
    <g id="g7125">
      <path className="cls-1" id="line7121" d="M204.94 200.5l14.84 14.84" />
      <path className="cls-1" id="line7123" d="M219.78 200.5l-14.84 14.84" />
    </g>
    <g id="g7131">
      <path className="cls-1" id="line7127" d="M107.61 107.78l14.85 14.84" />
      <path className="cls-1" id="line7129" d="M122.46 107.78l-14.85 14.84" />
    </g>
    <g id="g7137">
      <path className="cls-1" id="line7133" d="M34.29 107.78l14.85 14.84" />
      <path className="cls-1" id="line7135" d="M49.14 107.78l-14.85 14.84" />
    </g>
    <g id="g7143">
      <path className="cls-1" id="line7139" d="M379.79 315.63l14.85 14.84" />
      <path className="cls-1" id="line7141" d="M394.64 315.63l-14.85 14.84" />
    </g>
    <g id="g7149">
      <path className="cls-1" id="line7145" d="M438.08 315.63l14.84 14.84" />
      <path className="cls-1" id="line7147" d="M452.92 315.63l-14.84 14.84" />
    </g>
    <g id="g7155">
      <path className="cls-1" id="line7151" d="M492.7 315.63l14.85 14.84" />
      <path className="cls-1" id="line7153" d="M507.55 315.63l-14.85 14.84" />
    </g>
    <g id="g7161">
      <path className="cls-1" id="line7157" d="M548.8 315.63l14.84 14.84" />
      <path className="cls-1" id="line7159" d="M563.64 315.63l-14.84 14.84" />
    </g>
    <g id="g7167">
      <path className="cls-1" id="line7163" d="M604.89 315.63l14.84 14.84" />
      <path className="cls-1" id="line7165" d="M619.73 315.63l-14.84 14.84" />
    </g>
    <g id="g7173">
      <path className="cls-1" id="line7169" d="M604.89 205.57l14.84 14.84" />
      <path className="cls-1" id="line7171" d="M619.73 205.57l-14.84 14.84" />
    </g>
    <g id="g7179">
      <path className="cls-1" id="line7175" d="M507.57 107.78l14.84 14.84" />
      <path className="cls-1" id="line7177" d="M522.41 107.78l-14.84 14.84" />
    </g>
    <g id="g7185">
      <path className="cls-1" id="line7181" d="M434.25 107.78l14.84 14.84" />
      <path className="cls-1" id="line7183" d="M449.09 107.78l-14.84 14.84" />
    </g>
    <g id="g7191">
      <path className="cls-1" id="line7187" d="M717.08 205.57l14.84 14.84" />
      <path className="cls-1" id="line7189" d="M731.92 205.57l-14.84 14.84" />
    </g>
    <g id="g7197">
      <path className="cls-1" id="line7193" d="M664.33 315.63l14.85 14.84" />
      <path className="cls-1" id="line7195" d="M679.18 315.63l-14.85 14.84" />
    </g>
    <g id="g7203">
      <path className="cls-1" id="line7199" d="M717.08 315.63l14.84 14.84" />
      <path className="cls-1" id="line7201" d="M731.92 315.63l-14.84 14.84" />
    </g>
    <g id="g7209">
      <path className="cls-1" id="line7205" d="M859.08 315.63l14.84 14.84" />
      <path className="cls-1" id="line7207" d="M873.92 315.63l-14.84 14.84" />
    </g>
    <g id="g7215">
      <path className="cls-1" id="line7211" d="M915.08 315.63l14.84 14.84" />
      <path className="cls-1" id="line7213" d="M929.92 315.63l-14.84 14.84" />
    </g>
    <g id="g7221">
      <path className="cls-1" id="line7217" d="M88.36 315.63l14.85 14.84" />
      <path className="cls-1" id="line7219" d="M103.21 315.63l-14.85 14.84" />
    </g>
    <g id="g7227">
      <path className="cls-1" id="line7223" d="M30.08 315.63l14.84 14.84" />
      <path className="cls-1" id="line7225" d="M44.92 315.63l-14.84 14.84" />
    </g>
    <path
      className="cls-1"
      id="polyline7229"
      d="M204.94 257.95l7.42 7.42 7.42-7.42"
    />
    <path
      className="cls-1"
      id="polyline7231"
      d="M204.94 266.37l7.42 7.42 7.42-7.42"
    />
    <path
      className="cls-1"
      id="polyline7233"
      d="M219.78 192.82l-7.42-7.42-7.42 7.42"
    />
    <path
      className="cls-1"
      id="polyline7235"
      d="M219.78 184.4l-7.42-7.42-7.42 7.42"
    />
    <path
      className="cls-1"
      id="polyline7237"
      fill="none"
      stroke="#0f0"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M336.19 193.17l-7.42-7.42-7.42 7.42"
    />
    <path
      className="cls-1"
      id="polyline7239"
      fill="none"
      stroke="#0f0"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M336.19 184.75l-7.42-7.42-7.42 7.42"
    />
    <path
      className="cls-1"
      id="polyline7241"
      d="M619.73 192.82l-7.42-7.42-7.42 7.42"
    />
    <path
      className="cls-1"
      id="polyline7243"
      d="M619.73 184.4l-7.42-7.42-7.42 7.42"
    />
    <circle
      className="cls-1"
      cx={212.36}
      cy={66.32}
      r={25.98}
      id="circle7245"
    />
    <circle
      className="cls-1"
      cx={212.36}
      cy={40.34}
      r={25.98}
      id="circle7247"
    />
    <circle
      className="cls-1"
      cx={612.31}
      cy={66.32}
      r={25.98}
      id="circle7249"
    />
    <circle
      className="cls-1"
      cx={612.31}
      cy={40.34}
      r={25.98}
      id="circle7251"
    />
    <g id="g7275">
      <path className="cls-2" id="line7253" d="M917.5 96.36v117" />
      <path
        className="cls-2"
        id="polyline7255"
        d="M39.74 196.03v-45.46l-20-27"
      />
      <path className="cls-2" id="polyline7257" d="M445.5 196.03v-35l-20-27" />
      <path
        className="cls-2"
        id="polyline7259"
        d="M387.87 399.5v-39.02l-20-27"
      />
      <path
        className="cls-2"
        id="polyline7261"
        d="M923.61 399.5v-39.02l-20-27"
      />
      <path
        className="cls-2"
        id="polyline7263"
        d="M214.94 399.5v-39.02l-20-27"
      />
      <path
        className="cls-2"
        id="polyline7265"
        d="M154.05 399.5v-39.02l-20-27"
      />
      <g id="g7271">
        <path className="cls-2" id="line7267" d="M911.01 206.5l12.98 12.97" />
        <path className="cls-2" id="line7269" d="M923.99 206.5l-12.98 12.97" />
      </g>
      <circle
        className="cls-2"
        cx={917.5}
        cy={67.17}
        r={28.59}
        id="circle7273"
      />
    </g>
    <text className="cls-3" transform="translate(583.78 313.74)" id="text7277">
      1F5
    </text>
    <text className="cls-3" transform="translate(697.18 204.1)" id="text7279">
      Q5
    </text>
    <text className="cls-3" transform="translate(303.72 204.1)" id="text7281">
      Q4
    </text>
    <text className="cls-3" transform="translate(184.82 204.1)" id="text7283">
      Q2
    </text>
    <text className="cls-3" transform="translate(5.89 106.88)" id="text7285">
      2FP1
    </text>
    <text className="cls-3" transform="translate(75.85 106.88)" id="text7287">
      2FP2
    </text>
    <text className="cls-3" transform="translate(406.32 106.88)" id="text7289">
      1FP1
    </text>
    <text className="cls-3" transform="translate(475.81 106.88)" id="text7291">
      1FP2
    </text>
    <text className="cls-3" transform="translate(857.36 53)" id="text7293">
      GEN
    </text>
    <text
      transform="translate(839.48 71.83)"
      id="text7307"
      fill="#09b500"
      fontFamily="MyriadPro-Regular,Myriad Pro,sans-serif"
      fontSize={10}
    >
      <tspan id="tspan7295" letterSpacing=".01em">
        G
      </tspan>
      <tspan x={7.81} y={0} id="tspan7297">
        o
      </tspan>
      <tspan className="cls-6" x={14.4} y={0} id="tspan7299">
        t
      </tspan>
      <tspan x={18.3} y={0} id="tspan7301" letterSpacing="-.01em">
        o
      </tspan>
      <tspan x={24.8} y={0} id="tspan7303" letterSpacing=".01em">
        w
      </tspan>
      <tspan x={33.78} y={0} id="tspan7305">
        y
      </tspan>
    </text>
    <path
      d="M921.82 74.48a12.56 12.56 0 01-4.14.74 6.33 6.33 0 01-6.92-6.8c0-4 2.78-7 7.3-7a8.33 8.33 0 013.36.62L921 63.5a7 7 0 00-3-.58 5 5 0 00-5.42 5.42c0 3.42 2.06 5.44 5.2 5.44a5.67 5.67 0 002.32-.36v-4h-2.74V68h4.44z"
      id="path7309"
      fill="#445175"
    />
    <text
      transform="translate(855.71 91.32)"
      id="text7317"
      fill="#ff4f53"
      fontFamily="MyriadPro-Regular,Myriad Pro,sans-serif"
      fontSize={10}
    >
      <tspan id="tspan7311" letterSpacing={0}>
        S
      </tspan>
      <tspan className="cls-6" x={5.88} y={0} id="tspan7313">
        t
      </tspan>
      <tspan x={9.78} y={0} id="tspan7315">
        op
      </tspan>
    </text>
    <text className="cls-3" transform="translate(161.63 34.88)" id="text7319">
      TR2
    </text>
    <text className="cls-3" transform="translate(557 34.88)" id="text7321">
      TR1
    </text>
    <text className="cls-3" transform="translate(6.04 313.74)" id="text7323">
      2F1
    </text>
    <text className="cls-3" transform="translate(64.01 313.74)" id="text7325">
      2F2
    </text>
    <text className="cls-3" transform="translate(125.48 313.74)" id="text7327">
      2F3
    </text>
    <text className="cls-3" transform="translate(185.33 313.74)" id="text7329">
      2F4
    </text>
    <text className="cls-3" transform="translate(243.87 313.74)" id="text7331">
      2F5
    </text>
    <text className="cls-3" transform="translate(304.17 313.74)" id="text7333">
      2F6
    </text>
    <text className="cls-3" transform="translate(355.44 313.74)" id="text7335">
      1F1
    </text>
    <text className="cls-3" transform="translate(413.72 313.74)" id="text7337">
      1F2
    </text>
    <text className="cls-3" transform="translate(472.01 313.74)" id="text7339">
      1F3
    </text>
    <text className="cls-3" transform="translate(526.64 313.74)" id="text7341">
      1F4
    </text>
    <text className="cls-3" transform="translate(639.49 313.74)" id="text7343">
      1F6
    </text>
    <text className="cls-3" transform="translate(696 313.74)" id="text4479">
      1F7
    </text>
    <text className="cls-3" transform="translate(834.69 313.74)" id="text7345">
      3F1
    </text>
    <text className="cls-3" transform="translate(890.72 313.74)" id="text7347">
      3F2
    </text>
    <text className="cls-3" transform="translate(892.59 204.1)" id="text7349">
      Q3
    </text>
    <text className="cls-3" transform="translate(585.65 204.1)" id="text7351">
      Q1
    </text>
    <g cursor="pointer">
      <g className="redirectSwitch" id="g7397" data-element-name="B1B_P2">
        <path
          className="cls-12"
          id="rect7353"
          d="M10.58 402.32h53.83v75.74H10.58z"
        />
        <g id="g7359">
          <path
            className="cls-13"
            id="rect7355"
            d="M11.08 422.21h52.83v18.5H11.08z"
          />
          <path
            className="cls-12"
            d="M63.42 422.71v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path7357"
          />
        </g>
        <g id="g7365">
          <path
            className="cls-13"
            id="rect7361"
            d="M11.08 440.78h52.83v18.5H11.08z"
          />
          <path
            className="cls-12"
            d="M63.42 441.28v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path7363"
          />
        </g>
        <g id="g7371">
          <path
            className="cls-13"
            id="rect7367"
            d="M11.08 459.06h52.83v18.5H11.08z"
          />
          <path
            className="cls-12"
            d="M63.42 459.56v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path7369"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(13.45 414.69)"
          id="text7377"
        >
          <tspan className="cls-14" id="tspan7373" />
          <tspan x={3.9} y={0} id="tspan7375">
            B1B P2
          </tspan>
        </text>
        <text
          className="cls-15 kw B1B_P2"
          transform="translate(19.67 433.79)"
          id="text7383"
        />
        <text
          className="cls-15 A B1B_P2"
          transform="translate(19.67 451.74)"
          id="text7389"
        />
        <text
          className="cls-15 pf B1B_P2"
          transform="translate(19.67 470.8)"
          id="text7395"
        />
      </g>
      <g className="redirectSwitch" id="g7443" data-element-name="B2_RG">
        <path
          className="cls-12"
          id="rect7399"
          d="M68.3 402.32h53.83v75.74H68.3z"
        />
        <g id="g7405">
          <path
            className="cls-13"
            id="rect7401"
            d="M68.8 422.21h52.83v18.5H68.8z"
          />
          <path
            className="cls-12"
            d="M121.13 422.71v17.5H69.3v-17.5h51.83m1-1H68.3v19.5h53.83v-19.5z"
            id="path7403"
          />
        </g>
        <g id="g7411">
          <path
            className="cls-13"
            id="rect7407"
            d="M68.8 440.78h52.83v18.5H68.8z"
          />
          <path
            className="cls-12"
            d="M121.13 441.28v17.5H69.3v-17.5h51.83m1-1H68.3v19.5h53.83v-19.5z"
            id="path7409"
          />
        </g>
        <g id="g7417">
          <path
            className="cls-13"
            id="rect7413"
            d="M68.8 459.06h52.83v18.5H68.8z"
          />
          <path
            className="cls-12"
            d="M121.13 459.56v17.5H69.3v-17.5h51.83m1-1H68.3v19.5h53.83v-19.5z"
            id="path7415"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(74.17 414.69)"
          id="text7423"
        >
          <tspan className="cls-14" id="tspan7419" />
          <tspan x={3.9} y={0} id="tspan7421">
            B2 RG
          </tspan>
        </text>
        <text
          className="cls-15 kw B2_RG"
          transform="translate(77.39 433.79)"
          id="text7429"
        />
        <text
          className="cls-15 A B2_RG"
          transform="translate(77.39 451.74)"
          id="text7435"
        />
        <text
          className="cls-15 pf B2_RG"
          transform="translate(77.39 470.8)"
          id="text7441"
        />
      </g>
      <g className="redirectSwitch" id="g7487" data-element-name="B01_MSB2">
        <path
          className="cls-12"
          id="rect7445"
          d="M126.02 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7451">
          <path
            className="cls-13"
            id="rect7447"
            d="M126.52 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M178.85 422.71v17.5H127v-17.5h51.83m1-1H126v19.5h53.83v-19.5z"
            id="path7449"
          />
        </g>
        <g id="g7457">
          <path
            className="cls-13"
            id="rect7453"
            d="M126.52 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M178.85 441.28v17.5H127v-17.5h51.83m1-1H126v19.5h53.83v-19.5z"
            id="path7455"
          />
        </g>
        <g id="g7463">
          <path
            className="cls-13"
            id="rect7459"
            d="M126.52 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M178.85 459.56v17.5H127v-17.5h51.83m1-1H126v19.5h53.83v-19.5z"
            id="path7461"
          />
        </g>
        <g id="g7469">
          <path
            className="yellow"
            id="rect7465"
            d="M126.02 402.32h52.83v75.74h-52.83z"
          />
          <path
            className="cls-12"
            d="M63.42 254.13v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path7467"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(122.89 414.69)"
          id="text7473"
        >
          <tspan x={3.9} y={0} id="tspan7471">
            B01 MSB2
          </tspan>
        </text>
        <text
          className="cls-15 B01_MSB2"
          transform="translate(134.11 433.79)"
          id="text7477"
        >
          <tspan x={2.92} y={0} id="tspan7475">
            0kW
          </tspan>
        </text>
        <text
          className="cls-15 B01_MSB2"
          transform="translate(134.11 451.74)"
          id="text7481"
        >
          <tspan x={2.92} y={0} id="tspan7479">
            0A
          </tspan>
        </text>
        <text
          className="cls-15 B01_MSB2"
          transform="translate(134.11 470.8)"
          id="text7485"
        >
          <tspan x={2.92} y={0} id="tspan7483">
            0PF
          </tspan>
        </text>
      </g>
      <g className="redirectSwitch" id="g7531" data-element-name="B2_R1">
        <path
          className="cls-12"
          id="rect7489"
          d="M184.78 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7495">
          <path
            className="cls-13"
            id="rect7491"
            d="M185.28 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M237.61 422.71v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7493"
          />
        </g>
        <g id="g7501">
          <path
            className="cls-13"
            id="rect7497"
            d="M185.28 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M237.61 441.28v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7499"
          />
        </g>
        <g id="g7507">
          <path
            className="cls-13"
            id="rect7503"
            d="M185.28 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M237.61 459.56v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7505"
          />
        </g>
        <g id="g7513">
          <path
            className="yellow"
            id="rect7509"
            d="M184.78 402.32h52.83v75.74h-52.83z"
          />
          <path
            className="cls-12"
            d="M63.42 254.13v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path7511"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(190.65 414.69)"
          id="text7517"
        >
          <tspan x={3.9} y={0} id="tspan7515">
            B2 R1
          </tspan>
        </text>
        <text
          className="cls-15 B2_R1"
          transform="translate(193.87 433.79)"
          id="text7521"
        >
          <tspan x={2.92} y={0} id="tspan7519">
            0kW
          </tspan>
        </text>
        <text
          className="cls-15 B2_R1"
          transform="translate(193.87 451.74)"
          id="text7525"
        >
          <tspan x={2.92} y={0} id="tspan7523">
            0A
          </tspan>
        </text>
        <text
          className="cls-15 B2_R1"
          transform="translate(193.87 470.8)"
          id="text7529"
        >
          <tspan x={2.92} y={0} id="tspan7527">
            0PF
          </tspan>
        </text>
      </g>
      <g className="redirectSwitch" id="g7577" data-element-name="B1A">
        <path
          className="cls-12"
          id="rect7533"
          d="M243.16 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7539">
          <path
            className="cls-13"
            id="rect7535"
            d="M243.66 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M296 422.71v17.5h-51.84v-17.5H296m1-1h-53.84v19.5H297v-19.5z"
            id="path7537"
          />
        </g>
        <g id="g7545">
          <path
            className="cls-13"
            id="rect7541"
            d="M243.66 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M296 441.28v17.5h-51.84v-17.5H296m1-1h-53.84v19.5H297v-19.5z"
            id="path7543"
          />
        </g>
        <g id="g7551">
          <path
            className="cls-13"
            id="rect7547"
            d="M243.66 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M296 459.56v17.5h-51.84v-17.5H296m1-1h-53.84v19.5H297v-19.5z"
            id="path7549"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(255.03 414.69)"
          id="text7557"
        >
          <tspan className="cls-14" id="tspan7553" />
          <tspan x={3.9} y={0} id="tspan7555">
            B1A
          </tspan>
        </text>
        <text
          className="cls-15 kw B1A"
          transform="translate(251.25 433.79)"
          id="text7563"
        />
        <text
          className="cls-15 A B1A"
          transform="translate(251.25 451.74)"
          id="text7569"
        />
        <text
          className="cls-15 pf B1A"
          transform="translate(251.25 470.8)"
          id="text7575"
        />
      </g>
      <g className="redirectSwitch" id="g7623" data-element-name="Parking">
        <path
          className="cls-12"
          id="rect7579"
          d="M302.01 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7585">
          <path
            className="cls-13"
            id="rect7581"
            d="M302.51 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M354.84 422.71v17.5H303v-17.5h51.83m1-1H302v19.5h53.83v-19.5z"
            id="path7583"
          />
        </g>
        <g id="g7591">
          <path
            className="cls-13"
            id="rect7587"
            d="M302.51 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M354.84 441.28v17.5H303v-17.5h51.83m1-1H302v19.5h53.83v-19.5z"
            id="path7589"
          />
        </g>
        <g id="g7597">
          <path
            className="cls-13"
            id="rect7593"
            d="M302.51 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M354.84 459.56v17.5H303v-17.5h51.83m1-1H302v19.5h53.83v-19.5z"
            id="path7595"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(303.88 414.69)"
          id="text7603"
        >
          <tspan className="cls-14" id="tspan7599" />
          <tspan x={3.9} y={0} id="tspan7601">
            Parking
          </tspan>
        </text>
        <text
          className="cls-15 kw Parking"
          transform="translate(311.1 433.79)"
          id="text7609"
        />
        <text
          className="cls-15 A Parking"
          transform="translate(311.1 451.74)"
          id="text7615"
        />
        <text
          className="cls-15 pf Parking"
          transform="translate(311.1 470.8)"
          id="text7621"
        />
      </g>
      <g className="redirectSwitch" id="g7667" data-element-name="B01_MSB1">
        <path
          className="cls-12"
          id="rect7625"
          d="M360.87 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7631">
          <path
            className="cls-13"
            id="rect7627"
            d="M361.37 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M413.7 422.71v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7629"
          />
        </g>
        <g id="g7637">
          <path
            className="cls-13"
            id="rect7633"
            d="M361.37 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M413.7 441.28v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7635"
          />
        </g>
        <g id="g7643">
          <path
            className="cls-13"
            id="rect7639"
            d="M361.37 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M413.7 459.56v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7641"
          />
        </g>
        <g id="g7649">
          <path
            className="yellow"
            id="rect7645"
            d="M360.87 402.32h52.83v75.74h-52.83z"
          />
          <path
            className="cls-12"
            d="M413.7 459.56v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7647"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(357.73 414.69)"
          id="text7653"
        >
          <tspan x={3.9} y={0} id="tspan7651">
            B01 MSB1
          </tspan>
        </text>
        <text
          className="cls-15 B01_MSB1"
          transform="translate(370.95 433.79)"
          id="text7657"
        >
          <tspan x={2.92} y={0} id="tspan7655">
            0kW
          </tspan>
        </text>
        <text
          className="cls-15 B01_MSB1"
          transform="translate(370.95 451.74)"
          id="text7661"
        >
          <tspan x={2.92} y={0} id="tspan7659">
            0A
          </tspan>
        </text>
        <text
          className="cls-15 B01_MSB1"
          transform="translate(370.95 470.8)"
          id="text7665"
        >
          <tspan x={2.92} y={0} id="tspan7663">
            0PF
          </tspan>
        </text>
      </g>
      <g className="redirectSwitch" id="g7713" data-element-name="B3_komp.">
        <path
          className="cls-12"
          id="rect7669"
          d="M416.75 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7675">
          <path
            className="cls-13"
            id="rect7671"
            d="M417.25 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M469.58 422.71v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7673"
          />
        </g>
        <g id="g7681">
          <path
            className="cls-13"
            id="rect7677"
            d="M417.25 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M469.58 441.28v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7679"
          />
        </g>
        <g id="g7687">
          <path
            className="cls-13"
            id="rect7683"
            d="M417.25 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M469.58 459.56v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7685"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(414.62 414.69)"
          id="text7693"
        >
          <tspan className="cls-14" id="tspan7689" />
          <tspan x={3.9} y={0} id="tspan7691">
            B3 komp.
          </tspan>
        </text>
        <text
          className="cls-15 B3_komp_ kw"
          transform="translate(422.84 433.79)"
          id="text7699"
        >
          <tspan className="cls-6" id="tspan7695" />
          <tspan x={2.92} y={0} id="tspan7697">
            +0.0kW
          </tspan>
        </text>
        <text
          className="cls-15 B3_komp_ A"
          transform="translate(422.84 451.74)"
          id="text7705"
        >
          <tspan className="cls-6" id="tspan7701" />
          <tspan x={2.92} y={0} id="tspan7703">
            +0.0A
          </tspan>
        </text>
        <text
          className="cls-15 B3_komp_ pf"
          transform="translate(422.84 470.8)"
          id="text7711"
        >
          <tspan className="cls-6" id="tspan7707" />
          <tspan x={2.92} y={0} id="tspan7709">
            +0.0PF
          </tspan>
        </text>
      </g>
      <g className="redirectSwitch" id="g7759" data-element-name="B1A_komp.">
        <path
          className="cls-12"
          id="rect7715"
          d="M473.21 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7721">
          <path
            className="cls-13"
            id="rect7717"
            d="M473.71 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M526 422.71v17.5h-51.79v-17.5H526m1-1h-53.79v19.5H527v-19.5z"
            id="path7719"
          />
        </g>
        <g id="g7727">
          <path
            className="cls-13"
            id="rect7723"
            d="M473.71 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M526 441.28v17.5h-51.79v-17.5H526m1-1h-53.79v19.5H527v-19.5z"
            id="path7725"
          />
        </g>
        <g id="g7733">
          <path
            className="cls-13"
            id="rect7729"
            d="M473.71 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M526 459.56v17.5h-51.79v-17.5H526m1-1h-53.79v19.5H527v-19.5z"
            id="path7731"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(469.08 414.69)"
          id="text7739"
        >
          <tspan className="cls-14" id="tspan7735" />
          <tspan x={3.9} y={0} id="tspan7737">
            B1A komp.
          </tspan>
        </text>
        <text
          className="cls-15 kw B1A_komp_"
          transform="translate(481.3 433.79)"
          id="text7745"
        />
        <text
          className="cls-15 A B1A_komp_"
          transform="translate(481.3 451.74)"
          id="text7751"
        />
        <text
          className="cls-15 pf B1A_komp_"
          transform="translate(481.3 470.8)"
          id="text7757"
        />
      </g>
      <g className="redirectSwitch" id="g7805" data-element-name="B1A_klim.">
        <path
          className="cls-12"
          id="rect7761"
          d="M529.3 402.32h53.83v75.74H529.3z"
        />
        <g id="g7767">
          <path
            className="cls-13"
            id="rect7763"
            d="M529.8 422.21h52.83v18.5H529.8z"
          />
          <path
            className="cls-12"
            d="M582.13 422.71v17.5H530.3v-17.5h51.83m1-1H529.3v19.5h53.83v-19.5z"
            id="path7765"
          />
        </g>
        <g id="g7773">
          <path
            className="cls-13"
            id="rect7769"
            d="M529.8 440.78h52.83v18.5H529.8z"
          />
          <path
            className="cls-12"
            d="M582.13 441.28v17.5H530.3v-17.5h51.83m1-1H529.3v19.5h53.83v-19.5z"
            id="path7771"
          />
        </g>
        <g id="g7779">
          <path
            className="cls-13"
            id="rect7775"
            d="M529.8 459.06h52.83v18.5H529.8z"
          />
          <path
            className="cls-12"
            d="M582.13 459.56v17.5H530.3v-17.5h51.83m1-1H529.3v19.5h53.83v-19.5z"
            id="path7777"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(528.17 414.69)"
          id="text7785"
        >
          <tspan className="cls-14" id="tspan7781" />
          <tspan x={3.9} y={0} id="tspan7783">
            B1A klim.
          </tspan>
        </text>
        <text
          className="cls-15 kw B1A_klim_"
          transform="translate(538.39 433.79)"
          id="text7791"
        />
        <text
          className="cls-15 A B1A_klim_"
          transform="translate(538.39 451.74)"
          id="text7797"
        />
        <text
          className="cls-15 pf B1A_klim_"
          transform="translate(538.39 470.8)"
          id="text7803"
        />
      </g>
      <g className="redirectSwitch" id="g7851" data-element-name="R3_RG">
        <path
          className="cls-12"
          id="rect7807"
          d="M585.4 402.32h53.83v75.74H585.4z"
        />
        <g id="g7813">
          <path
            className="cls-13"
            id="rect7809"
            d="M585.9 422.21h52.83v18.5H585.9z"
          />
          <path
            className="cls-12"
            d="M638.23 422.71v17.5H586.4v-17.5h51.83m1-1H585.4v19.5h53.83v-19.5z"
            id="path7811"
          />
        </g>
        <g id="g7819">
          <path
            className="cls-13"
            id="rect7815"
            d="M585.9 440.78h52.83v18.5H585.9z"
          />
          <path
            className="cls-12"
            d="M638.23 441.28v17.5H586.4v-17.5h51.83m1-1H585.4v19.5h53.83v-19.5z"
            id="path7817"
          />
        </g>
        <g id="g7825">
          <path
            className="cls-13"
            id="rect7821"
            d="M585.9 459.06h52.83v18.5H585.9z"
          />
          <path
            className="cls-12"
            d="M638.23 459.56v17.5H586.4v-17.5h51.83m1-1H585.4v19.5h53.83v-19.5z"
            id="path7823"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(589.27 414.69)"
          id="text7831"
        >
          <tspan className="cls-14" id="tspan7827" />
          <tspan x={3.9} y={0} id="tspan7829">
            R3 RG
          </tspan>
        </text>
        <text
          className="cls-15 kw R3_RG"
          transform="translate(594.48 433.79)"
          id="text7837"
        />
        <text
          className="cls-15 A R3_RG"
          transform="translate(594.48 451.74)"
          id="text7843"
        />
        <text
          className="cls-15 pf R3_RG"
          transform="translate(594.48 470.8)"
          id="text7849"
        />
      </g>
      <g className="redirectSwitch" id="g7897" data-element-name="R2_RG">
        <path
          className="cls-12"
          id="rect7853"
          d="M641.49 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7859">
          <path
            className="cls-13"
            id="rect7855"
            d="M641.99 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M694.32 422.71v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7857"
          />
        </g>
        <g id="g7865">
          <path
            className="cls-13"
            id="rect7861"
            d="M641.99 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M694.32 441.28v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7863"
          />
        </g>
        <g id="g7871">
          <path
            className="cls-13"
            id="rect7867"
            d="M641.99 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M694.32 459.56v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7869"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(646.36 414.69)"
          id="text7877"
        >
          <tspan className="cls-14" id="tspan7873" />
          <tspan x={3.9} y={0} id="tspan7875">
            R2 RG
          </tspan>
        </text>
        <text
          className="cls-15 kw R2_RG"
          transform="translate(650.58 433.79)"
          id="text7883"
        />
        <text
          className="cls-15 A R2_RG"
          transform="translate(650.58 451.74)"
          id="text7889"
        />
        <text
          className="cls-15 pf R2_RG"
          transform="translate(650.58 470.8)"
          id="text7895"
        />
      </g>
      <g className="redirectSwitch" id="g7943" data-element-name="B1B_P2_2">
        <path
          className="cls-12"
          id="rect7899"
          d="M697.58 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7905">
          <path
            className="cls-13"
            id="rect7901"
            d="M698.08 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M750.42 422.71v17.5h-51.84v-17.5h51.84m1-1h-53.84v19.5h53.84v-19.5z"
            id="path7903"
          />
        </g>
        <g id="g7911">
          <path
            className="cls-13"
            id="rect7907"
            d="M698.08 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M750.42 441.28v17.5h-51.84v-17.5h51.84m1-1h-53.84v19.5h53.84v-19.5z"
            id="path7909"
          />
        </g>
        <g id="g7917">
          <path
            className="cls-13"
            id="rect7913"
            d="M698.08 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M750.42 459.56v17.5h-51.84v-17.5h51.84m1-1h-53.84v19.5h53.84v-19.5z"
            id="path7915"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(700.45 414.69)"
          id="text7923"
        >
          <tspan className="cls-14" id="tspan7919" />
          <tspan x={3.9} y={0} id="tspan7921">
            B1B P2
          </tspan>
        </text>
        <text
          className="cls-15 kw B1B_P2"
          transform="translate(706.67 433.79)"
          id="text7929"
        />
        <text
          className="cls-15 A B1B_P2"
          transform="translate(706.67 451.74)"
          id="text7935"
        />
        <text
          className="cls-15 pf B1B_P2"
          transform="translate(706.67 470.8)"
          id="text7941"
        />
      </g>
      <g className="redirectSwitch" id="g7989" data-element-name="B2_serwer">
        <path
          className="cls-12"
          id="rect7945"
          d="M839.56 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7951">
          <path
            className="cls-13"
            id="rect7947"
            d="M840.06 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M892.39 422.71v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7949"
          />
        </g>
        <g id="g7957">
          <path
            className="cls-13"
            id="rect7953"
            d="M840.06 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M892.39 441.28v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7955"
          />
        </g>
        <g id="g7963">
          <path
            className="cls-13"
            id="rect7959"
            d="M840.06 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M892.39 459.56v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path7961"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(836.43 414.69)"
          id="text7969"
        >
          <tspan className="cls-14" id="tspan7965" />
          <tspan x={3.9} y={0} id="tspan7967">
            B2 Serwer
          </tspan>
        </text>
        <text
          className="cls-15 kw B2_serwer"
          transform="translate(849.64 433.79)"
          id="text7975"
        />
        <text
          className="cls-15 A B2_serwer"
          transform="translate(849.64 451.74)"
          id="text7981"
        />
        <text
          className="cls-15 pf B2_serwer"
          transform="translate(849.64 470.8)"
          id="text7987"
        />
      </g>
      <g className="redirectSwitch" id="g8033" data-element-name="B01_MSG">
        <path
          className="cls-12"
          id="rect7991"
          d="M895.58 402.32h53.83v75.74h-53.83z"
        />
        <g id="g7997">
          <path
            className="cls-13"
            id="rect7993"
            d="M896.08 422.21h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M948.42 422.71v17.5h-51.84v-17.5h51.84m1-1h-53.84v19.5h53.84v-19.5z"
            id="path7995"
          />
        </g>
        <g id="g8003">
          <path
            className="cls-13"
            id="rect7999"
            d="M896.08 440.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M948.42 441.28v17.5h-51.84v-17.5h51.84m1-1h-53.84v19.5h53.84v-19.5z"
            id="path8001"
          />
        </g>
        <g id="g8009">
          <path
            className="cls-13"
            id="rect8005"
            d="M896.08 459.06h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M948.42 459.56v17.5h-51.84v-17.5h51.84m1-1h-53.84v19.5h53.84v-19.5z"
            id="path8007"
          />
        </g>
        <g id="g8015">
          <path
            className="yellow"
            id="rect8011"
            d="M895.58 402.32h52.83v75.74h-52.83z"
          />
          <path
            className="cls-12"
            d="M413.7 459.56v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path8013"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(893.45 414.69)"
          id="text8019"
        >
          <tspan x={3.9} y={0} id="tspan8017">
            B01 MSG
          </tspan>
        </text>
        <text
          className="cls-15 B01_MSG"
          transform="translate(906.67 433.79)"
          id="text8023"
        >
          <tspan x={2.92} y={0} id="tspan8021">
            0kW
          </tspan>
        </text>
        <text
          className="cls-15 B01_MSG"
          transform="translate(906.67 451.74)"
          id="text8027"
        >
          <tspan x={2.92} y={0} id="tspan8025">
            0A
          </tspan>
        </text>
        <text
          className="cls-15 B01_MSG"
          transform="translate(906.67 470.8)"
          id="text8031"
        >
          <tspan x={2.92} y={0} id="tspan8029">
            0PF
          </tspan>
        </text>
      </g>
      <g className="redirectGen" id="g8079">
        <path
          className="cls-12"
          id="rect8035"
          d="M835.08 107.05h53.83v75.74h-53.83z"
        />
        <g id="g8041">
          <path
            className="cls-13"
            id="rect8037"
            d="M835.58 126.95h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M887.91 127.45V145h-51.83v-17.5h51.83m1-1h-53.83V146h53.83v-19.5z"
            id="path8039"
          />
        </g>
        <g id="g8047">
          <path
            className="cls-13"
            id="rect8043"
            d="M835.58 145.52h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M887.91 146v17.5h-51.83V146h51.83m1-1h-53.83v19.5h53.83V145z"
            id="path8045"
          />
        </g>
        <g id="g8053">
          <path
            className="cls-13"
            id="rect8049"
            d="M835.58 163.8h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M887.91 164.3v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path8051"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(833.95 119.42)"
          id="text8059"
        >
          <tspan className="cls-14" id="tspan8055" />
          <tspan x={3.9} y={0} id="tspan8057">
            Generator
          </tspan>
        </text>
        <text
          className="cls-15"
          transform="translate(845.17 138.53)"
          id="text8065"
        >
          <tspan className="cls-6" id="tspan8061" />
          <tspan x={2.92} y={0} id="tspan8063">
            0.0kW
          </tspan>
        </text>
        <text
          className="cls-15"
          transform="translate(845.17 156.48)"
          id="text8071"
        >
          <tspan className="cls-6" id="tspan8067" />
          <tspan x={2.92} y={0} id="tspan8069">
            0.0kVar
          </tspan>
        </text>
        <text
          className="cls-15"
          transform="translate(845.17 175.53)"
          id="text8077"
        >
          <tspan className="cls-6" id="tspan8073" />
          <tspan x={2.92} y={0} id="tspan8075">
            0PF
          </tspan>
        </text>
      </g>
      <g className="redirectSwitch" id="g8125" data-element-name="RPZOK_2">
        <path
          className="cls-12"
          id="rect8081"
          d="M488.08 196.89h53.83v75.74h-53.83z"
        />
        <g id="g8087">
          <path
            className="cls-13"
            id="rect8083"
            d="M488.58 216.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M540.91 217.28v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path8085"
          />
        </g>
        <g id="g8093">
          <path
            className="cls-13"
            id="rect8089"
            d="M488.58 235.35h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M540.91 235.85v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path8091"
          />
        </g>
        <g id="g8099">
          <path
            className="cls-13"
            id="rect8095"
            d="M488.58 253.63h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M540.91 254.13v17.5h-51.83v-17.5h51.83m1-1h-53.83v19.5h53.83v-19.5z"
            id="path8097"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(490.94 209.26)"
          id="text8105"
        >
          <tspan className="cls-14" id="tspan8101" />
          <tspan x={3.9} y={0} id="tspan8103">
            RPZOK
          </tspan>
        </text>
        <text
          className="cls-15 RPZOK_2 kw"
          transform="translate(492.16 228.36)"
          id="text8111"
        >
          <tspan className="cls-6" id="tspan8107" />
          <tspan x={2.92} y={0} id="tspan8109">
            +0.0kW
          </tspan>
        </text>
        <text
          className="cls-15 RPZOK_2 A"
          transform="translate(492.16 246.31)"
          id="text8117"
        >
          <tspan className="cls-6" id="tspan8113" />
          <tspan x={2.92} y={0} id="tspan8115">
            +0.0A
          </tspan>
        </text>
        <text
          className="cls-15 RPZOK_2 pf"
          transform="translate(492.16 265.37)"
          id="text8123"
        >
          <tspan className="cls-6" id="tspan8119" />
          <tspan x={2.92} y={0} id="tspan8121">
            +0.0PF
          </tspan>
        </text>
      </g>
      <g className="redirectSwitch" id="g8169" data-element-name="B01_MSF_2">
        <path
          className="cls-12"
          id="rect8127"
          d="M418.58 196.89h53.83v75.74h-53.83z"
        />
        <g id="g8133">
          <path
            className="cls-13"
            id="rect8129"
            d="M419.08 216.78h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M471.42 217.28v17.5h-51.84v-17.5h51.84m1-1h-53.84v19.5h53.84v-19.5z"
            id="path8131"
          />
        </g>
        <g id="g8139">
          <path
            className="cls-13"
            id="rect8135"
            d="M419.08 235.35h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M471.42 235.85v17.5h-51.84v-17.5h51.84m1-1h-53.84v19.5h53.84v-19.5z"
            id="path8137"
          />
        </g>
        <g id="g8145">
          <path
            className="cls-13"
            id="rect8141"
            d="M419.08 253.63h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M471.42 254.13v17.5h-51.84v-17.5h51.84m1-1h-53.84v19.5h53.84v-19.5z"
            id="path8143"
          />
        </g>
        <g id="g8151">
          <path
            className="yellow"
            id="rect8147"
            d="M418.58 196.89h52.83v75.74h-52.83z"
          />
          <path
            className="cls-12"
            d="M63.42 254.13v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path8149"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(417.45 209.26)"
          id="text8155"
        >
          <tspan x={3.9} y={0} id="tspan8153">
            B01 MSF
          </tspan>
        </text>
        <text
          className="cls-15"
          transform="translate(428.67 228.36)"
          id="text8159"
        >
          <tspan x={2.92} y={0} id="tspan8157">
            0kW
          </tspan>
        </text>
        <text
          className="cls-15"
          transform="translate(428.67 246.31)"
          id="text8163"
        >
          <tspan x={2.92} y={0} id="tspan8161">
            0A
          </tspan>
        </text>
        <text
          className="cls-15"
          transform="translate(428.67 265.37)"
          id="text8167"
        >
          <tspan x={2.92} y={0} id="tspan8165">
            0PF
          </tspan>
        </text>
      </g>
      <g className="redirectTR" id="g8215" data-element-name="Trafo_TR2">
        <path
          className="cls-12"
          id="rect8171"
          d="M251.44 16.56h53.83V92.3h-53.83z"
        />
        <g id="g8177">
          <path
            className="cls-13"
            id="rect8173"
            d="M251.94 36.45h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M304.27 37v17.5h-51.83V37h51.83m1-1h-53.83v19.5h53.83V36z"
            id="path8175"
          />
        </g>
        <g id="g8183">
          <path
            className="cls-13"
            id="rect8179"
            d="M251.94 55.02h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M304.27 55.52V73h-51.83V55.52h51.83m1-1h-53.83V74h53.83V54.52z"
            id="path8181"
          />
        </g>
        <g id="g8189">
          <path
            className="cls-13"
            id="rect8185"
            d="M251.94 73.3h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M304.27 73.8v17.5h-51.83V73.8h51.83m1-1h-53.83v19.5h53.83V72.8z"
            id="path8187"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(249.31 28.93)"
          id="text8195"
        >
          <tspan className="cls-14" id="tspan8191" />
          <tspan x={3.9} y={0} id="tspan8193">
            Trafo TR2
          </tspan>
        </text>
        <text
          className="cls-15 kw TR2"
          transform="translate(254.52 48.03)"
          id="text8201"
        >
          <tspan className="cls-6" id="tspan8197">
            +
          </tspan>
          <tspan x={5.92} y={0} id="tspan8199">
            105.2k W
          </tspan>
        </text>
        <text
          className="cls-15 kvar TR2"
          transform="translate(254.52 65.98)"
          id="text8207"
        >
          <tspan className="cls-6" id="tspan8203">
            +
          </tspan>
          <tspan x={5.92} y={0} id="tspan8205">
            0.5kVar
          </tspan>
        </text>
        <text
          className="cls-15 pf TR2"
          transform="translate(254.52 85.04)"
          id="text8213"
        >
          <tspan className="cls-6" id="tspan8209">
            +
          </tspan>
          <tspan x={5.92} y={0} id="tspan8211">
            0.554PF
          </tspan>
        </text>
      </g>
      <g className="redirectTR" id="g8261" data-element-name="Trafo_TR1">
        <path
          className="cls-12"
          id="rect8217"
          d="M650.56 16.56h53.83V92.3h-53.83z"
        />
        <g id="g8223">
          <path
            className="cls-13"
            id="rect8219"
            d="M651.06 36.45h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M703.39 37v17.5h-51.83V37h51.83m1-1h-53.83v19.5h53.83V36z"
            id="path8221"
          />
        </g>
        <g id="g8229">
          <path
            className="cls-13"
            id="rect8225"
            d="M651.06 55.02h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M703.39 55.52V73h-51.83V55.52h51.83m1-1h-53.83V74h53.83V54.52z"
            id="path8227"
          />
        </g>
        <g id="g8235">
          <path
            className="cls-13"
            id="rect8231"
            d="M651.06 73.3h52.83v18.5h-52.83z"
          />
          <path
            className="cls-12"
            d="M703.39 73.8v17.5h-51.83V73.8h51.83m1-1h-53.83v19.5h53.83V72.8z"
            id="path8233"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(648.43 28.93)"
          id="text8241"
        >
          <tspan className="cls-14" id="tspan8237" />
          <tspan x={3.9} y={0} id="tspan8239">
            Trafo TR1
          </tspan>
        </text>
        <text
          className="cls-15 kw TR1"
          transform="translate(656.65 48.03)"
          id="text8247"
        >
          <tspan className="cls-6" id="tspan8243">
            +
          </tspan>
          <tspan x={5.92} y={0} id="tspan8245">
            155.4k W
          </tspan>
        </text>
        <text
          className="cls-15 kvar TR1"
          transform="translate(656.65 65.98)"
          id="text8253"
        >
          <tspan className="cls-6" id="tspan8249">
            +
          </tspan>
          <tspan x={5.92} y={0} id="tspan8251">
            0.5kVar
          </tspan>
        </text>
        <text
          className="cls-15 pf TR1"
          transform="translate(656.65 85.04)"
          id="text8259"
        >
          <tspan className="cls-6" id="tspan8255">
            +
          </tspan>
          <tspan x={5.92} y={0} id="tspan8257">
            0.553PF
          </tspan>
        </text>
      </g>
      <g className="redirectSwitch" id="g8305" data-element-name="B01_MSF">
        <path
          className="cls-12"
          id="rect8263"
          d="M10.58 196.89h53.83v75.74H10.58z"
        />
        <g id="g8269">
          <path
            className="cls-13"
            id="rect8265"
            d="M11.08 216.78h52.83v18.5H11.08z"
          />
          <path
            className="cls-12"
            d="M63.42 217.28v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path8267"
          />
        </g>
        <g id="g8275">
          <path
            className="cls-13"
            id="rect8271"
            d="M11.08 235.35h52.83v18.5H11.08z"
          />
          <path
            className="cls-12"
            d="M63.42 235.85v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path8273"
          />
        </g>
        <g id="g8281">
          <path
            className="cls-13"
            id="rect8277"
            d="M11.08 253.63h52.83v18.5H11.08z"
          />
          <path
            className="cls-12"
            d="M63.42 254.13v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path8279"
          />
        </g>
        <g id="g8287">
          <path
            className="yellow"
            id="rect8283"
            d="M10.58 196.89h52.83v75.74H10.58z"
          />
          <path
            className="cls-12"
            d="M63.42 254.13v17.5H11.58v-17.5h51.84m1-1H10.58v19.5h53.84v-19.5z"
            id="path8285"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(9.45 209.26)"
          id="text8291"
        >
          <tspan x={3.9} y={0} id="tspan8289">
            B01 MSF
          </tspan>
        </text>
        <text
          className="cls-15"
          transform="translate(21.67 228.36)"
          id="text8295"
        >
          <tspan x={2.92} y={0} id="tspan8293">
            0kW
          </tspan>
        </text>
        <text
          className="cls-15"
          transform="translate(21.67 246.31)"
          id="text8299"
        >
          <tspan x={2.92} y={0} id="tspan8297">
            0A
          </tspan>
        </text>
        <text
          className="cls-15"
          transform="translate(21.67 265.37)"
          id="text8303"
        >
          <tspan x={2.92} y={0} id="tspan8301">
            0PF
          </tspan>
        </text>
      </g>
      <g className="redirectSwitch" id="g8351" data-element-name="RPZOK">
        <path
          className="cls-12"
          id="rect8307"
          d="M88.12 196.89h53.83v75.74H88.12z"
        />
        <g id="g8313">
          <path
            className="cls-13"
            id="rect8309"
            d="M88.62 216.78h52.83v18.5H88.62z"
          />
          <path
            className="cls-12"
            d="M141 217.28v17.5H89.12v-17.5H141m1-1H88.12v19.5H142v-19.5z"
            id="path8311"
          />
        </g>
        <g id="g8319">
          <path
            className="cls-13"
            id="rect8315"
            d="M88.62 235.35h52.83v18.5H88.62z"
          />
          <path
            className="cls-12"
            d="M141 235.85v17.5H89.12v-17.5H141m1-1H88.12v19.5H142v-19.5z"
            id="path8317"
          />
        </g>
        <g id="g8325">
          <path
            className="cls-13"
            id="rect8321"
            d="M88.62 253.63h52.83v18.5H88.62z"
          />
          <path
            className="cls-12"
            d="M141 254.13v17.5H89.12v-17.5H141m1-1H88.12v19.5H142v-19.5z"
            id="path8323"
          />
        </g>
        <text
          className="cls-3"
          transform="translate(90.99 209.26)"
          id="text8331"
        >
          <tspan className="cls-14" id="tspan8327" />
          <tspan x={3.9} y={0} id="tspan8329">
            RPZOK
          </tspan>
        </text>
        <text
          className="cls-15 RPZOK kw"
          transform="translate(94.21 228.36)"
          id="text8337"
        >
          <tspan className="cls-6" id="tspan8333" />
          <tspan x={2.92} y={0} id="tspan8335">
            +0.0kW
          </tspan>
        </text>
        <text
          className="cls-15 RPZOK A"
          transform="translate(94.21 246.31)"
          id="text8343"
        >
          <tspan className="cls-6" id="tspan8339" />
          <tspan x={2.92} y={0} id="tspan8341">
            +0.0A
          </tspan>
        </text>
        <text
          className="cls-15 RPZOK pf"
          transform="translate(94.21 265.37)"
          id="text8349"
        >
          <tspan className="cls-6" id="tspan8345" />
          <tspan x={2.92} y={0} id="tspan8347">
            0.00PF
          </tspan>
        </text>
      </g>
    </g>
    <path
      id="line8363"
      className="cls-1"
      fill="none"
      stroke="#0f0"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.856 282.5H328.93"
    />
    <path
      id="line8365"
      className="cls-1"
      fill="none"
      stroke="#0f0"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M387.051 282.495h336.774"
    />
    <path
      className="cls-1"
      id="polyline7229-3"
      fill="none"
      stroke="#0f0"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M321.442 258.449l7.42 7.42 7.42-7.42"
    />
    <path
      className="cls-1"
      id="polyline7231-7"
      fill="none"
      stroke="#0f0"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M321.442 266.869l7.42 7.42 7.42-7.42"
    />
  </svg>
);

export default SvgSiemensPlansza1;
