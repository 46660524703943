import React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import MainScreen from './mainScreen';
import SchemaSzr from './schema-szr/schema-szr';
import Diagnostyka from './diagnostyka';
import SecondParams from './elewacja/secondParams';
import Elewacja from './elewacja';

class MainRoutes extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/schema-szr-main"
            component={SchemaSzr}
          />
          <Route
            path="/diagnostyka-main"
            component={Diagnostyka}
          />
          <Route
            path="/elewacja"
            component={Elewacja}
          />
          <Route
            path="/schemat"
            component={Elewacja}
          />
          <Route
            path="/siec"
            component={Elewacja}
          />
          <Route
            path="/"
            component={MainScreen}
          />
          <Redirect to="/dashboard" />
        </Switch>
      </Router>
    );
  }
}

export default MainRoutes;
