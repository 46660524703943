import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Diagnostyka extends Component {
  render() {
    return (
      <div className="actum-szr-params-container">
        <div className="actum-szr-sidebar">
          <div className="actum-szr-sidebar-rows">
            <Link to="/diagnostyka-main" className="actum-szr-param-active actum-szr-yellow-gradient">
                            Diagnostyka
                            PLC
            </Link>
            <Link to="/diagnostyka-main" className="actum-szr-param-button actum-szr-gradient">Użytkownicy</Link>
          </div>
          <div className="actum-szr-sidebar-bottom-buttons">
            <Link to="/dashboard" className="actum-szr-param-bottom actum-szr-gradient">
              <img src="assets/siemens-home.svg" alt="home" className="actum-szr-footer-icon" />
            </Link>
            <Link
              to={`/${this.props.history.location.pathname.split('/')[1]}-main`}
              role="button"
              tabIndex={0}
              className="actum-szr-param-bottom actum-szr-gradient"
            >
              <img src="../assets/siemens-Asset_10.svg" alt="home" className="actum-szr-footer-icon" />
            </Link>
          </div>
        </div>
        <div
          className="actum-szr-content"
        >
          <div
            className="actum-szr-diagnostic-data-container"
          >
            <div
              className="actum-szr-diagnostic-table-wrapper"
            >
              <div
                className="actum-szr-highlighted-notification"
              >
                {' '}
                                Diagnostic
                                overview
                {' '}
              </div>
              <table className="actum-szr-diagnostic-table">
                <tbody>
                  <tr>
                    <td className="actum-szr-diagnostic-table-header">Status</td>
                    <td className="actum-szr-diagnostic-table-header">Name</td>
                    <td className="actum-szr-diagnostic-table-header">Operating state</td>
                    <td className="actum-szr-diagnostic-table-header">Slot</td>
                    <td className="actum-szr-diagnostic-table-header">Type</td>
                    <td className="actum-szr-diagnostic-table-header">Order number</td>
                    <td className="actum-szr-diagnostic-table-header">Address</td>
                    <td className="actum-szr-diagnostic-table-header">Plant designation</td>
                    <td className="actum-szr-diagnostic-table-header">Location identifier</td>
                  </tr>
                  <tr>
                    <td>
                      <img src="assets/status_icon_1.png" alt="status" className="actum-szr-status-icon1" />
                    </td>
                    <td>Plant</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className="actum-szr-diagnostic-icon-wrapper">
                      <img
                            src="assets/status_icon_2.png"
                            alt="status"
                            className="actum-szr-status-icon2"
                          />
                    </td>
                    <td>Sterownik SZR - 5A3</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>32*</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className="actum-szr-diagnostic-icon-wrapper">
                      <img
                            src="assets/status_icon_2.png"
                            alt="status"
                            className="actum-szr-status-icon2"
                          />
                    </td>
                    <td>Sterownik RG - 5A6</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>32*</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Diagnostyka;
