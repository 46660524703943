import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';

class Ustawienia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 'czasyZasilania',
      stateTime: 0,
    };
  }

  setStateTime = (stateTime) => {
    this.setState({ stateTime });
  };

    setContent = (content) => {
      this.setState({ content, stateTime: 0 });
    };


    switchContent = (content, stateTime) => {
      switch (content) {
        default:
        case 'czasyZasilania':
          return (
            <>
              <label className="actum-szr-nap-label">Czas opóźnienia zaniku napięcia TR1</label>
              <input
                type="text"
                placeholder="10s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">Czas opóźnienia powrotu napięcia TR1</label>
              <input
                type="text"
                placeholder="5s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">
                            Czas opóźnienia zaniku napięcia
                            TR2
              </label>
              <input
                type="text"
                placeholder="10s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">
                            Czas opóźnienia powrotu napięcia
                            TR2
              </label>
              <input
                type="text"
                placeholder="5s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">
                            Czas opóźnienia zaniku napięcia
                            GEN
              </label>
              <input
                type="text"
                placeholder="10s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">
                            Czas opóźnienia powrotu
                            napięcia GEN
              </label>
              <input
                type="text"
                placeholder="5s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">
                            Czas opóźnienia startu
                            GEN
              </label>
              <input
                type="text"
                placeholder="10s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">
                            Czas opóźnienia stopu
                            GEN
              </label>
              <input
                type="text"
                placeholder="5s"
                className="actum-szr-param-input actum-szr-reason"
              />
            </>

          );
        case 'czasyKontroli':
          return (
            <>
              <label className="actum-szr-nap-label">Czas kontroli działania wyłączników</label>
              <input
                type="text"
                placeholder="1s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">Czas kontroli załączania stycznika GEN</label>
              <input
                type="text"
                placeholder="1s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">
                            Czas kontroli wyłączania stycznika
                            GEN
              </label>
              <input
                type="text"
                placeholder="1s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">Czas kontroli działania GEN</label>
              <input
                type="text"
                placeholder="1s"
                className="actum-szr-param-input actum-szr-reason"
              />
            </>
          );
        case 'czasyStanu':
          return (
            <>
              <label className="actum-szr-nap-label">
                {`Czas opóźnienia przejścia do stanu ${stateTime}`}
              </label>
              <input
                type="text"
                placeholder="10s"
                className="actum-szr-param-input actum-szr-reason"
              />
              <label className="actum-szr-nap-label">
                {`Czas opóźnienia przejścia do stanu ${stateTime}`}
              </label>
              <input
                type="text"
                placeholder="10s"
                className="actum-szr-param-input actum-szr-reason"
              />
              {stateTime !== 0 && (
                <>
                  <label className="actum-szr-nap-label">Czas opóźnienia załączania Q1</label>
                  <input
                    type="text"
                    placeholder="1s"
                    className="actum-szr-param-input actum-szr-reason"
                  />
                  <label className="actum-szr-nap-label">Czas opóźnienia załączania Q2</label>
                  <input
                    type="text"
                    placeholder="1s"
                    className="actum-szr-param-input actum-szr-reason"
                  />
                  <label className="actum-szr-nap-label">
                    Czas opóźnienia wyłączania
                    Q3
                  </label>
                  <input
                    type="text"
                    placeholder="1s"
                    className="actum-szr-param-input actum-szr-reason"
                  />
                  <label className="actum-szr-nap-label">
                    Czas opóźnienia załączania
                    Q4
                  </label>
                  <input
                    type="text"
                    placeholder="1s"
                    className="actum-szr-param-input actum-szr-reason"
                  />
                  <label className="actum-szr-nap-label">
                    Czas opóźnienia
                    załączania Q5
                  </label>
                  <input
                    type="text"
                    placeholder="1s"
                    className="actum-szr-param-input actum-szr-reason"
                  />
                </>
              )}
            </>
          );
      }
    };


    render() {
      const { content, stateTime } = this.state;
      return (
        <div className="actum-szr-diagnostyka-rows">
          <div className="actum-szr-content-buttons actum-szr-narrow-buttons">

            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setContent('czasyZasilania')}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': content === 'czasyZasilania' && stateTime === 0,
                'actum-szr-param-button actum-szr-gradient': content !== 'czasyZasilania' || stateTime !== 0,
              })}
            >
                      Czasy
                      zasilania
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setContent('czasyKontroli')}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': content === 'czasyKontroli' && stateTime === 0,
                'actum-szr-param-button actum-szr-gradient': content !== 'czasyKontroli' || stateTime !== 0,
              })}
            >
                      Czasy
                      kontroli
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setContent('czasyStanu')}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': content === 'czasyStanu' && stateTime === 0,
                'actum-szr-param-button actum-szr-gradient': content !== 'czasyStanu' || stateTime !== 0,
              })}
            >
                      Czasy
                      stanu
                      0
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setStateTime(1)}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': stateTime === 1,
                'actum-szr-param-button actum-szr-gradient': stateTime !== 1,
              })}
            >
                      Czasy
                      stanu
                      1
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setStateTime(2)}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': stateTime === 2,
                'actum-szr-param-button actum-szr-gradient': stateTime !== 2,
              })}
            >
                      Czasy
                      stanu
                      2
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setStateTime(3)}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': stateTime === 3,
                'actum-szr-param-button actum-szr-gradient': stateTime !== 3,
              })}
            >
                      Czasy
                      stanu
                      3
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.setStateTime(4)}
              className={classNames({
                'actum-szr-param-active actum-szr-yellow-gradient': stateTime === 4,
                'actum-szr-param-button actum-szr-gradient': stateTime !== 4,
              })}
            >
                      Czasy
                      stanu
                      4
            </div>
          </div>
          <div className="actum-szr-diagnostyka-szr">
            <div className="actum-szr-settings-wrapper">
              {this.switchContent(content, stateTime)}
            </div>
          </div>
        </div>
      );
    }
}

export default Ustawienia;
