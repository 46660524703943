import * as React from "react";

const SvgWykresYAxis = (props) => (
  <svg viewBox="0 0 40 549" {...props}>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={478.479}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={4.224} y={478.479}>
        {"0"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={452.688}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={3.132} y={452.688}>
        {"10"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={427.8}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={3.777} y={427.8}>
        {"20"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={404.228}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={3.637} y={404.228}>
        {"30"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={379.813}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={4.085} y={379.813}>
        {"40"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={357.785}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={3.719} y={357.785}>
        {"50"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={334.155}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={3.69} y={334.155}>
        {"60"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={310.246}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={3.69} y={310.246}>
        {"70"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={288.834}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={4.027} y={288.834}>
        {"80"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={264.757}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={3.69} y={264.757}>
        {"90"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={240.579}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={3.146} y={240.579}>
        {"100"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={217.542}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={2.968} y={217.542}>
        {"110"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={194.515}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={2.939} y={194.515}>
        {"120"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={170.506}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={2.602} y={170.506}>
        {"130"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={148.152}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={2.881} y={148.152}>
        {"140"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={124.032}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={2.8} y={124.032}>
        {"150"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={100.389}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={2.8} y={100.389}>
        {"160"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={78.79}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={2.886} y={78.79}>
        {"170"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={53.55}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={2.8} y={53.55}>
        {"180"}
      </tspan>
    </text>
    <text
      style={{
        lineHeight: 1.25,
      }}
      x={4}
      y={31.335}
      className="yAxisText"
      fontWeight={400}
      fontSize={13.333}
      fontFamily="sans-serif"
      letterSpacing={0}
      wordSpacing={0}
      fill="gray"
    >
      <tspan x={3.107} y={31.335}>
        {"190"}
      </tspan>
    </text>
  </svg>
);

export default SvgWykresYAxis;
