import React, { Component } from 'react';
import classNames from 'classnames';

class Diagnostyka extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 'diagnostyka',
      switch1: 1,
      statystyka: 'TR1',
    };
    const { redirect } = this.props;
    if (redirect) {
      this.state = {
        content: 'statystyka-szr',
        switch1: 1,
        statystyka: 'TR1',
      };
    }
  }

  setSwitch = (switch1) => {
    this.setState({ switch1 });
  };

  setStatystyka = (statystyka) => {
    this.setState({ statystyka });
  };

  setContent = (content) => {
    this.setState({ content });
  };


  render() {
    const { content, switch1, statystyka } = this.state;
    const menu = (
      <div className="actum-szr-content-buttons">
        <div
          role="button"
          tabIndex={0}
          onClick={() => { this.setContent('diagnostyka'); }}
          className={classNames({
            'actum-szr-param-active actum-szr-yellow-gradient': content === 'diagnostyka',
            'actum-szr-param-button actum-szr-gradient': content !== 'diagnostyka',
          })}
        >
                Kontrola aparat.
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => { this.setContent('statystyka-aparat'); }}
          className={classNames({
            'actum-szr-param-active actum-szr-yellow-gradient': content === 'statystyka-aparat',
            'actum-szr-param-button actum-szr-gradient': content !== 'statystyka-aparat',
          })}
        >
                Statystyka aparat.
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => { this.setContent('statystyka-zas'); }}
          className={classNames({
            'actum-szr-param-active actum-szr-yellow-gradient': content === 'statystyka-zas',
            'actum-szr-param-button actum-szr-gradient': content !== 'statystyka-zas',
          })}
        >
                Statystyka zas.
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => { this.setContent('statystyka-szr'); }}
          className={classNames({
            'actum-szr-param-active actum-szr-yellow-gradient': content === 'statystyka-szr',
            'actum-szr-param-button actum-szr-gradient': content !== 'statystyka-szr',
          })}
        >
                Statystyka SZR
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => { this.setContent('statystyka-gen'); }}
          className={classNames({
            'actum-szr-param-active actum-szr-yellow-gradient': content === 'statystyka-gen',
            'actum-szr-param-button actum-szr-gradient': content !== 'statystyka-gen',
          })}
        >
                Statystyka GEN
        </div>
      </div>
    );
    switch (content) {
      default:
      case 'diagnostyka':
        return (
          <div className="actum-szr-diagnostyka-rows">
            {menu}
            <div className="actum-szr-diagnostyka-diodes-wrapper">
              <div className="actum-szr-diagnostyka-diodes">
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria załączania Q1
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria załączania Q4
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria wyłączania Q1
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria wyłączania Q4
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria załączania Q2
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria wyłączania Q5
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria wyłączania Q2
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria wyłączania Q5
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria załączania Q3
                  <div />
                </div>
                <div className="actum-szr-button-container" />
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                                    Awaria wyłączania Q3
                  <div />
                </div>
              </div>
            </div>
          </div>
        );
      case 'statystyka-aparat':
        return (
          <div className="actum-szr-diagnostyka-aparat-rows">
            {menu}
            <div className="actum-szr-content-buttons actum-szr-second-row">
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.setSwitch(1)}
                className={classNames({
                  'actum-szr-param-active actum-szr-yellow-gradient': switch1 === 1,
                  'actum-szr-param-button actum-szr-gradient': switch1 !== 1,
                })}
              >
Wyłącznik Q1
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.setSwitch(2)}
                className={classNames({
                  'actum-szr-param-active actum-szr-yellow-gradient': switch1 === 2,
                  'actum-szr-param-button actum-szr-gradient': switch1 !== 2,
                })}
              >
                        Wyłącznik Q2
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.setSwitch(3)}
                className={classNames({
                  'actum-szr-param-active actum-szr-yellow-gradient': switch1 === 3,
                  'actum-szr-param-button actum-szr-gradient': switch1 !== 3,
                })}
              >
                        Wyłącznik Q3
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.setSwitch(4)}
                className={classNames({
                  'actum-szr-param-active actum-szr-yellow-gradient': switch1 === 4,
                  'actum-szr-param-button actum-szr-gradient': switch1 !== 4,
                })}
              >
                        Wyłącznik Q4
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.setSwitch(5)}
                className={classNames({
                  'actum-szr-param-active actum-szr-yellow-gradient': switch1 === 5,
                  'actum-szr-param-button actum-szr-gradient': switch1 !== 5,
                })}
              >
                        Wyłącznik Q5
              </div>

            </div>
            <div className="actum-szr-stats">
              <div className="actum-szr-stats-subsection">
                <label className="actum-szr-nap-label">
                  {`Liczba załączeń Q${switch1}`}
                </label>
                <div className="actum-szr-reason-container">
                  <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                  <input type="text" placeholder="12" className="actum-szr-param-input actum-szr-reason" />
                </div>
                <label className="actum-szr-nap-label">Ostatnie załączenie</label>
                <input
                  type="text"
                  placeholder="12:10:31 05/08/2019"
                  className="actum-szr-param-input actum-szr-reason"
                />
              </div>
              <div className="actum-szr-stats-subsection">
                <label className="actum-szr-nap-label">
                  {`Liczba wyłaczeń Q${switch1}`}
                </label>
                <div className="actum-szr-reason-container">
                  <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                  <input type="text" placeholder="36" className="actum-szr-param-input actum-szr-reason" />
                </div>
                <label className="actum-szr-nap-label">Ostatnie wyłączenie</label>
                <input
                  type="text"
                  placeholder="12:10:31 05/08/2019"
                  className="actum-szr-param-input actum-szr-reason"
                />
              </div>
              <div className="actum-szr-stats-subsection">
                <label className="actum-szr-nap-label">
                  {`Liczba wyzwoleń Q${switch1}`}
                </label>
                <div className="actum-szr-reason-container">
                  <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                  <input type="text" placeholder="12" className="actum-szr-param-input actum-szr-reason" />
                </div>
                <label className="actum-szr-nap-label">Ostatnie wyzwolenie</label>
                <input
                  type="text"
                  placeholder="12:10:31 05/08/2019"
                  className="actum-szr-param-input actum-szr-reason"
                />
              </div>
              <div className="actum-szr-stats-subsection">
                <label className="actum-szr-nap-label">
                  {`Liczba wysunięć Q${switch1}`}
                </label>
                <div className="actum-szr-reason-container">
                  <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                  <input type="text" placeholder="73" className="actum-szr-param-input actum-szr-reason" />
                </div>
                <label className="actum-szr-nap-label">Ostatnie wysunięcie</label>
                <input
                  type="text"
                  placeholder="12:10:31 05/08/2019"
                  className="actum-szr-param-input actum-szr-reason"
                />
              </div>
            </div>
          </div>
        );
      case 'statystyka-zas':
        return (
          <div className="actum-szr-diagnostyka-aparat-rows">
            {menu}
            <div className="actum-szr-content-buttons actum-szr-second-row">

              <div
                role="button"
                tabIndex={0}
                onClick={() => { this.setStatystyka('TR1'); }}
                className={classNames({
                  'actum-szr-param-active actum-szr-yellow-gradient': statystyka === 'TR1',
                  'actum-szr-param-button actum-szr-gradient': statystyka !== 'TR1',
                })}
              >
                            Transformator TR1
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={() => { this.setStatystyka('TR2'); }}
                className={classNames({
                  'actum-szr-param-active actum-szr-yellow-gradient': statystyka === 'TR2',
                  'actum-szr-param-button actum-szr-gradient': statystyka !== 'TR2',
                })}
              >
                            Transformator TR2
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={() => { this.setStatystyka('GEN'); }}
                className={classNames({
                  'actum-szr-param-active actum-szr-yellow-gradient': statystyka === 'GEN',
                  'actum-szr-param-button actum-szr-gradient': statystyka !== 'GEN',
                })}
              >
                            Generator GEN
              </div>

            </div>
            <div className="actum-szr-stats">
              <div className="actum-szr-stats-subsection">
                <label className="actum-szr-nap-label">
                  {`Liczba zaników ${statystyka}`}
                </label>
                <div className="actum-szr-reason-container">
                  <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                  <input type="text" placeholder="12" className="actum-szr-param-input actum-szr-reason" />
                </div>
                <label className="actum-szr-nap-label">Ostatni zanik</label>
                <input
                  type="text"
                  placeholder="12:10:31 05/08/2019"
                  className="actum-szr-param-input actum-szr-reason"
                />
              </div>
              <div className="actum-szr-stats-subsection">
                <label className="actum-szr-nap-label">
                  {`Liczba powrotów ${statystyka}`}
                </label>
                <div className="actum-szr-reason-container">
                  <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                  <input type="text" placeholder="36" className="actum-szr-param-input actum-szr-reason" />
                </div>
                <label className="actum-szr-nap-label">Ostatni powrót</label>
                <input
                  type="text"
                  placeholder="12:10:31 05/08/2019"
                  className="actum-szr-param-input actum-szr-reason"
                />
              </div>
            </div>
          </div>
        );
      case 'statystyka-szr':
        return (
          <div className="actum-szr-diagnostyka-rows">
            {menu}
            <div className="actum-szr-diagnostyka-szr">
              <div className="actum-szr-diagnostyka-stats-inner-wrapper">
                <div className="actum-szr-stats-subsection">
                  <label className="actum-szr-nap-label">Liczba przełączeń SZR</label>
                  <div className="actum-szr-reason-container">
                    <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                    <input type="text" placeholder="12" className="actum-szr-param-input actum-szr-reason" />
                  </div>
                  <label className="actum-szr-nap-label">Ostatnie przełączenie</label>
                  <input
                    type="text"
                    placeholder="12:10:31 05/08/2019"
                    className="actum-szr-param-input actum-szr-reason"
                  />
                </div>
              </div>
              <label className="actum-szr-nap-label actum-szr-margin-left-40">Ostatni stan: Oczekiwanie</label>
              <div className="actum-szr-button-container ">
                <div className="actum-szr-indicator-button actum-szr-red-gradient" />
                        Zasilanie 24V DC - zasilacz 1
                <div />
              </div>
              <div className="actum-szr-button-container ">
                <div className="actum-szr-indicator-button actum-szr-red-gradient" />
                        Zasilanie 24V DC - zasilacz 2
                <div />
              </div>
              <div className="actum-szr-button-container    ">
                <div className="actum-szr-indicator-button actum-szr-red-gradient" />
                        Zasilanie 24V DC
                <div />
              </div>
            </div>
          </div>
        );
      case 'statystyka-gen':
        return (
          <div className="actum-szr-diagnostyka-rows">
            {menu}
            <div className="actum-szr-diagnostyka-szr">
              <div className="actum-szr-button-container">
                <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                            Awaria załączania stycznika startu generatora
                <div />
              </div>
              <div className="actum-szr-button-container">
                <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                            Awaria wyłączania stycznika startu generatora
                <div />
              </div>
              <div className="actum-szr-button-container">
                <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                            Awaria startu generatora
                <div />
              </div>
              <div className="actum-szr-button-container">
                <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                            Awaria stopu generatora
                <div />
              </div>
            </div>
          </div>
        );
    }
  }
}

export default Diagnostyka;
