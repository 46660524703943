import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logo from './assets/siemens-logo.svg';

class Layout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    time: new Date(),
  };

  componentDidMount() {
    setInterval(() => this.setState({
      time: new Date(),
    }), 1000);
  }

  getTime = (date) => {
    let h = date.getHours(); // 0 - 23
    let m = date.getMinutes(); // 0 - 59
    let s = date.getSeconds(); // 0 - 59
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let session = 'AM';

    if (h === 0) {
      h = 12;
    }

    if (h > 12) {
      h -= 12;
      session = 'PM';
    }

    h = (h < 10) ? `0${h}` : h;
    m = (m < 10) ? `0${m}` : m;
    s = (s < 10) ? `0${s}` : s;

    const time = `${h}:${m}:${s} ${session}`;
    const calendar = `${day}/${month}/${year}`;
    return `${calendar} ${time}`;
  };

  render() {
    const { children } = this.props;
    const { time } = this.state;
    return (
      <>
        <div className="actum-szr-wrapper">
          <img
            className="actum-szr-siemens-logo"
            src={Logo}
            alt="Siemens Logo"
          />
          <div className="actum-szr-simatic-hmi">SIMATIC HMI</div>
          <div className="actum-szr-touch-text">TOUCH</div>
          <div className="actum-szr-container">
            <div className="actum-szr-header">
              <div className="actum-szr-header-row">
                <div className="actum-szr-user">Użytkownik:</div>
                <div className="actum-szr-buttons">
                  <button type="button" className="actum-szr-button-blue">TRYB SZR: AUTO</button>
                  <button type="button" className="actum-szr-button-green">PLC SZR: RUN</button>
                  <button type="button" className="actum-szr-button-green">PLC RG: RUN</button>
                </div>
                <div className="actum-szr-date" id="actum-szr-date">
                  {this.getTime(time)}
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      </>
    );
  }
}

export default Layout;
