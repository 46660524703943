import * as React from "react";

const SvgTraPrad = (props) => (
  <svg viewBox="-339 328 600 158" {...props}>
    <path
      fill="none"
      stroke="#808083"
      strokeWidth={0.25}
      strokeMiterlimit={10}
      d="M-331.8 462.9H261"
    />
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-328.2 462.9v1.2M-326.4 462.9v1.2M-324.7 462.9v1.2M-323 462.9v1.2M-321.6 462.9v1.2M-319.9 462.9v1.2M-318.2 462.9v1.2M-316.2 462.9v1.2M-314.5 462.9v1.2M-313.1 462.9v1.2M-311.4 462.9v1.2M-309.7 462.9v1.2M-308 462.9v1.2M-306.3 462.9v1.2M-304.9 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-303.2 462.9v1.2M-301.5 462.9v1.2M-299.8 462.9v1.2M-298.1 462.9v1.2M-296.7 462.9v1.2M-295 462.9v1.2M-293.3 462.9v1.2M-291.3 462.9v1.2M-289.6 462.9v1.2M-288.1 462.9v1.2M-286.4 462.9v1.2M-284.7 462.9v1.2M-283 462.9v1.2M-281.3 462.9v1.2M-279.9 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeMiterlimit={10}>
      <path
        strokeWidth={0.25}
        d="M-278 462.9v1.1M-276.2 462.9v1.1M-274.5 462.9v1.1M-272.8 462.9v1.1M-271.4 462.9v1.1M-269.7 462.9v1.1M-268 462.9v1.1M-266 462.9v1.1M-264.3 462.9v1.1M-262.9 462.9v1.1M-261.2 462.9v1.1M-259.5 462.9v1.1M-257.8 462.9v1.1M-256.1 462.9v1.1"
      />
      <path strokeWidth={0.316} d="M-254.7 462.9v1.9" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-253 462.9v1.1M-251.3 462.9v1.1M-249.6 462.9v1.1M-247.9 462.9v1.1M-246.5 462.9v1.1M-244.8 462.9v1.1M-243.1 462.9v1.1M-241.1 462.9v1.1M-239.4 462.9v1.1M-238 462.9v1.1M-236.3 462.9v1.1M-234.6 462.9v1.1M-232.9 462.9v1.1M-231.2 462.9v1.1M-229.7 462.9v1.1" />
    </g>
    <g fill="none" stroke="#000" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-599.2 455v1.2M-597.4 455v1.2M-595.7 455v1.2M-594 455v1.2M-592.6 455v1.2M-590.9 455v1.2M-589.2 455v1.2M-587.2 455v1.2M-585.5 455v1.2M-584.1 455v1.2M-582.4 455v1.2M-580.7 455v1.2M-579 455v1.2M-577.3 455v1.2M-575.9 455v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-227.9 462.9v1.2M-226.1 462.9v1.2M-224.4 462.9v1.2M-222.7 462.9v1.2M-221.3 462.9v1.2M-219.6 462.9v1.2M-217.9 462.9v1.2M-215.9 462.9v1.2M-214.2 462.9v1.2M-212.8 462.9v1.2M-211.1 462.9v1.2M-209.4 462.9v1.2M-207.7 462.9v1.2M-206 462.9v1.2M-204.6 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeMiterlimit={10}>
      <path
        strokeWidth={0.25}
        d="M-202.9 462.9v1.2M-201.2 462.9v1.2M-199.5 462.9v1.2M-197.8 462.9v1.2M-196.4 462.9v1.2M-194.7 462.9v1.2M-193 462.9v1.2M-191 462.9v1.2M-189.3 462.9v1.2M-187.9 462.9v1.2M-186.2 462.9v1.2M-184.5 462.9v1.2M-182.8 462.9v1.2M-181.1 462.9v1.2"
      />
      <path strokeWidth={0.31} d="M-179.6 462.9v1.9" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-177.8 462.9v1.2M-176.1 462.9v1.2M-174.4 462.9v1.2M-172.7 462.9v1.2M-171.3 462.9v1.2M-169.6 462.9v1.2M-167.9 462.9v1.2M-165.9 462.9v1.2M-164.2 462.9v1.2M-162.8 462.9v1.2M-161.1 462.9v1.2M-159.4 462.9v1.2M-157.7 462.9v1.2M-156 462.9v1.2M-154.5 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-152.9 462.9v1.2M-151.1 462.9v1.2M-149.4 462.9v1.2M-147.7 462.9v1.2M-146.3 462.9v1.2M-144.6 462.9v1.2M-142.9 462.9v1.2M-140.9 462.9v1.2M-139.2 462.9v1.2M-137.8 462.9v1.2M-136.1 462.9v1.2M-134.4 462.9v1.2M-132.7 462.9v1.2M-131 462.9v1.2M-129.6 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeMiterlimit={10}>
      <path
        strokeWidth={0.25}
        d="M-127.8 462.9v1.2M-126 462.9v1.2M-124.3 462.9v1.2M-122.6 462.9v1.2M-121.2 462.9v1.2M-119.5 462.9v1.2M-117.8 462.9v1.2M-115.8 462.9v1.2M-114.1 462.9v1.2M-112.7 462.9v1.2M-111 462.9v1.2M-109.3 462.9v1.2M-107.6 462.9v1.2M-105.9 462.9v1.2"
      />
      <path strokeWidth={0.31} d="M-104.2 462.9v1.9" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-102.8 462.9v1.2M-101.1 462.9v1.2M-99.4 462.9v1.2M-97.7 462.9v1.2M-96.3 462.9v1.2M-94.6 462.9v1.2M-92.9 462.9v1.2M-90.9 462.9v1.2M-89.2 462.9v1.2M-87.8 462.9v1.2M-86.1 462.9v1.2M-84.4 462.9v1.2M-82.7 462.9v1.2M-81 462.9v1.2M-79.5 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-75.4 462.9v1.2M-73.7 462.9v1.2M-72 462.9v1.2M-70.3 462.9v1.2M-68.9 462.9v1.2M-67.2 462.9v1.2M-65.5 462.9v1.2M-63.5 462.9v1.2M-61.8 462.9v1.2M-60.4 462.9v1.2M-58.7 462.9v1.2M-57 462.9v1.2M-55.3 462.9v1.2M-53.6 462.9v1.2M-52.1 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeMiterlimit={10}>
      <path
        strokeWidth={0.25}
        d="M-50.5 462.9v1.2M-48.7 462.9v1.2M-47 462.9v1.2M-45.3 462.9v1.2M-43.9 462.9v1.2M-42.2 462.9v1.2M-40.5 462.9v1.2M-38.5 462.9v1.2M-36.8 462.9v1.2M-35.4 462.9v1.2M-33.7 462.9v1.2M-32 462.9v1.2M-30.3 462.9v1.2"
      />
      <path strokeWidth={0.31} d="M-28.9 462.9v1.9" />
      <path strokeWidth={0.25} d="M-27.2 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-25.5 462.9v1.2M-23.7 462.9v1.2M-22 462.9v1.2M-20.3 462.9v1.2M-18.9 462.9v1.2M-17.2 462.9v1.2M-15.5 462.9v1.2M-13.5 462.9v1.2M-11.8 462.9v1.2M-10.4 462.9v1.2M-8.7 462.9v1.2M-7 462.9v1.2M-5.3 462.9v1.2M-3.6 462.9v1.2M-2.2 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M-.6 462.9v1.2M1.2 462.9v1.2M2.9 462.9v1.2M4.6 462.9v1.2M6 462.9v1.2M7.7 462.9v1.2M9.4 462.9v1.2M11.4 462.9v1.2M13.1 462.9v1.2M14.5 462.9v1.2M16.2 462.9v1.2M17.9 462.9v1.2M19.6 462.9v1.2M21.3 462.9v1.2M22.7 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeMiterlimit={10}>
      <path
        strokeWidth={0.25}
        d="M24.7 462.9v1.2M26.4 462.9v1.2M28.1 462.9v1.2M29.8 462.9v1.2M31.2 462.9v1.2M32.9 462.9v1.2M34.6 462.9v1.2M36.6 462.9v1.2M38.3 462.9v1.2M39.8 462.9v1.2M41.5 462.9v1.2M43.2 462.9v1.2M44.9 462.9v1.2"
      />
      <path strokeWidth={0.31} d="M46.3 462.9v1.9" />
      <path strokeWidth={0.25} d="M48 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M49.6 462.9v1.2M51.4 462.9v1.2M53.1 462.9v1.2M54.8 462.9v1.2M56.2 462.9v1.2M57.9 462.9v1.2M59.6 462.9v1.2M61.6 462.9v1.2M63.3 462.9v1.2M64.7 462.9v1.2M66.4 462.9v1.2M68.1 462.9v1.2M69.8 462.9v1.2M71.5 462.9v1.2M72.9 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M74.7 462.9v1.2M76.5 462.9v1.2M78.2 462.9v1.2M79.9 462.9v1.2M81.3 462.9v1.2M83 462.9v1.2M84.7 462.9v1.2M86.7 462.9v1.2M88.4 462.9v1.2M89.8 462.9v1.2M91.5 462.9v1.2M93.2 462.9v1.2M94.9 462.9v1.2M96.6 462.9v1.2M98 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeMiterlimit={10}>
      <path
        strokeWidth={0.25}
        d="M99.7 462.9v1.2M101.4 462.9v1.2M103.1 462.9v1.2M104.8 462.9v1.2M106.3 462.9v1.2M108 462.9v1.2M109.7 462.9v1.2M111.6 462.9v1.2M113.3 462.9v1.2M114.8 462.9v1.2M116.5 462.9v1.2M118.2 462.9v1.2M119.9 462.9v1.2"
      />
      <path strokeWidth={0.31} d="M121.6 462.9v1.9" />
      <path strokeWidth={0.25} d="M123 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M124.8 462.9v1.2M126.6 462.9v1.2M128.3 462.9v1.2M130 462.9v1.2M131.4 462.9v1.2M133.1 462.9v1.2M134.8 462.9v1.2M136.8 462.9v1.2M138.5 462.9v1.2M139.9 462.9v1.2M141.6 462.9v1.2M143.3 462.9v1.2M145 462.9v1.2M146.7 462.9v1.2M148.1 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M149.8 462.9v1.2M151.5 462.9v1.2M153.2 462.9v1.2M154.9 462.9v1.2M156.4 462.9v1.2M158.1 462.9v1.2M159.8 462.9v1.2M161.7 462.9v1.2M163.4 462.9v1.2M164.9 462.9v1.2M166.6 462.9v1.2M168.3 462.9v1.2M170 462.9v1.2M171.7 462.9v1.2M173.1 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeMiterlimit={10}>
      <path
        strokeWidth={0.25}
        d="M174.9 462.9v1.2M176.7 462.9v1.2M178.4 462.9v1.2M180.1 462.9v1.2M181.5 462.9v1.2M183.2 462.9v1.2M184.9 462.9v1.2M186.9 462.9v1.2M188.6 462.9v1.2M190 462.9v1.2M191.7 462.9v1.2M193.4 462.9v1.2M195.1 462.9v1.2"
      />
      <path strokeWidth={0.31} d="M196.8 462.9v1.9" />
      <path strokeWidth={0.25} d="M198.2 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M199.9 462.9v1.2M201.6 462.9v1.2M203.3 462.9v1.2M205 462.9v1.2M206.4 462.9v1.2M208.1 462.9v1.2M209.8 462.9v1.2M211.8 462.9v1.2M213.5 462.9v1.2M214.9 462.9v1.2M216.6 462.9v1.2M218.3 462.9v1.2M220 462.9v1.2M221.7 462.9v1.2M223.2 462.9v1.2" />
    </g>
    <g fill="none" stroke="#808083" strokeWidth={0.25} strokeMiterlimit={10}>
      <path d="M225 462.9v1.2M226.7 462.9v1.2M228.4 462.9v1.2M230.1 462.9v1.2M231.5 462.9v1.2M233.2 462.9v1.2M234.9 462.9v1.2M236.9 462.9v1.2M238.6 462.9v1.2M240 462.9v1.2M241.7 462.9v1.2M243.4 462.9v1.2M245.1 462.9v1.2M246.8 462.9v1.2M248.2 462.9v1.2" />
    </g>
    <path
      fill="none"
      stroke="#808083"
      strokeWidth={0.25}
      strokeMiterlimit={10}
      d="M249.9 462.9v1.2M251.6 462.9v1.2M253.3 462.9v1.2M255 462.9v1.2M256.5 462.9v1.2M258.2 462.9v1.2M259.9 462.9v1.2M261.8 462.9v1.2"
    />
    <text
      className="time"
      transform="translate(-329.875 468.51)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="date"
      transform="translate(-329.875 473.31)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="time"
      transform="translate(-264.332 468.51)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="date"
      transform="translate(-264.332 473.31)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="time"
      transform="translate(-189.274 468.51)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="date"
      transform="translate(-189.274 473.31)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="time"
      transform="translate(-114.121 468.51)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="date"
      transform="translate(-114.121 473.31)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="time"
      transform="translate(-39 468.51)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="date"
      transform="translate(-39 473.31)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="time"
      transform="translate(36.633 468.51)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="date"
      transform="translate(36.633 473.31)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="time"
      transform="translate(113.343 468.51)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="date"
      transform="translate(113.343 473.31)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="time"
      transform="translate(186.876 468.51)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <text
      className="date"
      transform="translate(186.876 473.31)"
      fill="#808083"
      fontFamily="Roboto-Regular"
      fontSize={4}
    />
    <path
      d="M-326.6 404.6s147.5 7.5 162 4.9c14.4-2.6 53.3-3.9 87.4-3.2s26.9 4 56.5 5.4C8.8 413 45.9 408 74.6 407s87-.1 106.7 1.8c25.5 2.4 82.1.4 82.1.4"
      fill="none"
      stroke="#e30613"
      strokeWidth={0.5}
      strokeMiterlimit={10}
    />
    <path
      d="M-326.8 418.8s147.5-12.1 162-7.9 53.3 6.3 87.4 5.2c34.1-1.1 26.9-6.5 56.5-8.6 29.6-2.1 66.7 6 95.4 7.6s87.1.1 106.7-2.9c28.8-4.5 82.6-1.2 82.6-1.2"
      fill="none"
      stroke="#009640"
      strokeWidth={0.5}
      strokeMiterlimit={10}
    />
    <path
      d="M261.5 402s-150.8 7.9-171.6 5.6c-21.2-2.3-49.2-13.5-83.3-12.3-34.1 1.2-31 13.7-60.6 16.1s-66.7-6.7-95.4-8.5-95.6-2.3-106.7 3.3c-11.8 5.9-71.6 3-71.6 3"
      fill="none"
      stroke="#009fe3"
      strokeWidth={0.5}
      strokeMiterlimit={10}
    />
  </svg>
);

export default SvgTraPrad;
