import React from 'react';
import classNames from 'classnames';
import '../../../styles/wykres.css';
import { Link } from 'react-router-dom';
import { swapDataGraph } from '../../../dataSwapperGraph';
import { WykresYAxis, WykresZero } from '../../../assets';

class ParametryGen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 'parametry',
    };
    if (this.props.location.state) {
      this.state = {
        content: this.props.location.state.newContent,
      };
    }
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
       function changeData() {
        getData('nap', 0, 0, 0, '', 'V');
        getData('percent', 0, 0, 0, '', '%');
        getData('wsp', 0, 0, 0, '', '');
        getData('pr', 0, 0, 0, '', 'A');

        getData('m_1', 0, 0, 0, '', 'kW');
        getData('m_2', 0, 0, 0, '', 'kVar');
        getData('m_3', 0, 0, 0, '', 'kVA');
    }

    updateData();
    `;
    document.body.appendChild(script);
  }

  componentDidUpdate() {
    const { content } = this.state;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    switch (content) {
      default:
      case 'parametry':
        script.innerHTML = `
          function changeData() {
            getData('nap', 0, 0, 0, '', 'V');
            getData('percent', 0, 0, 0, '', '%');
            getData('wsp', 0, 0, 0, '', '');
            getData('pr', 0, 0, 0, '', 'A');
            getData('m_1', 0, 0, 0, '', 'kW');
            getData('m_2', 0, 0, 0, '', 'kVar');
            getData('m_3', 0, 0, 0, '', 'kVA');
          }
          updateData();
          `;
        break;
      case 'aparat':
      case 'zasilanie':
        break;
      case 'mocCzynnaPobrana':
      case 'mocCzynnaOddana':
      case 'mocBiernaPobrana':
      case 'mocBiernaOddana':
        swapDataGraph(900, 10, 0);
        break;
      case 'napiecieN':
      case 'napiecieL':
      case 'prad':
        swapDataGraph(14, 10, 0);
        break;
      case 'zdarzenia':
        script.innerHTML = `
          parents = document.getElementsByClassName("actum-szr-events-element");
          rows = parents.length/4;
          for (let i = 0; i < rows; i++) {
            if (i % 2 === 0) {
              for(let j = 0; j < 4; j++) {
                const elem = parents[i*4 + j];
                elem.classList.add("actum-szr-events-element-nth");
              }
            }
          }
        `;
        break;
    }
    document.body.appendChild(script);
  }


  setContent = (content) => {
    this.setState({ content });
  };

  render() {
    const { content } = this.state;
    const menu = (
      <div id="update" className="actum-szr-sidebar actum-szr-content-tall">
        <div className="actum-szr-sidebar-rows actum-szr-sidebar-12-rows">
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('parametry')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'parametry',
              'actum-szr-param-button actum-szr-gradient ': content !== 'parametry',
            })}
          >
              Parametry
              zas.
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('aparat')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'aparat',
              'actum-szr-param-button actum-szr-gradient': content !== 'aparat',
            })}
          >
              Aparat
              zabezp.
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('zasilanie')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'zasilanie',
              'actum-szr-param-button actum-szr-gradient': content !== 'zasilanie',
            })}
          >
              Zasilanie
              GEN
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('statystyka')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'statystyka',
              'actum-szr-param-button actum-szr-gradient': content !== 'statystyka',
            })}
          >
              Statystyka
              GEN
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('mocCzynnaPobrana')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'mocCzynnaPobrana',
              'actum-szr-param-button actum-szr-gradient': content !== 'mocCzynnaPobrana',
            })}
          >
              Moc
              czynna pob.
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('mocCzynnaOddana')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'mocCzynnaOddana',
              'actum-szr-param-button actum-szr-gradient': content !== 'mocCzynnaOddana',
            })}
          >
              Moc
              czynna odd.
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('mocBiernaPobrana')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'mocBiernaPobrana',
              'actum-szr-param-button actum-szr-gradient': content !== 'mocBiernaPobrana',
            })}
          >
              Moc
              bierna pob.
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('mocBiernaOddana')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'mocBiernaOddana',
              'actum-szr-param-button actum-szr-gradient': content !== 'mocBiernaOddana',
            })}
          >
              Moc
              bierna odd.
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('napiecieN')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'napiecieN',
              'actum-szr-param-button actum-szr-gradient': content !== 'napiecieN',
            })}
          >
            {' '}
              Napięcie
              L-N
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('napiecieL')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'napiecieL',
              'actum-szr-param-button actum-szr-gradient': content !== 'napiecieL',
            })}
          >
              Napięcie
              L-L
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('prad')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'prad',
              'actum-szr-param-button actum-szr-gradient': content !== 'prad',
            })}
          >
              Prąd
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.setContent('zdarzenia')}
            className={classNames({
              'actum-szr-param-active actum-szr-yellow-gradient': content === 'zdarzenia',
              'actum-szr-param-button actum-szr-gradient': content !== 'zdarzenia',
            })}
          >
              Zdarzenia
          </div>
        </div>
        <div className="actum-szr-sidebar-bottom-buttons">
          <Link to="/dashboard" className="actum-szr-param-bottom actum-szr-gradient">
            <img src="../assets/siemens-home.svg" alt="home" className="actum-szr-footer-icon" />
          </Link>
          <Link
            to={`/${this.props.history.location.pathname.split('/')[1]}-main`}
            role="button"
            tabIndex={0}
            className="actum-szr-param-bottom actum-szr-gradient"
          >
            <img src="../assets/siemens-Asset_10.svg" alt="home" className="actum-szr-footer-icon" />
          </Link>
        </div>
      </div>
    );
    switch (content) {
      default:
      case ' parametry':
        return (
          <>
            {menu}
            <div className="actum-szr-content actum-szr-content-tall">
              <div className="actum-szr-param-content-rows">
                <div className="actum-szr-title">Zasilanie GEN</div>
                <div className="actum-szr-title">Napięcie</div>
                <div className="actum-szr-title">Asymetria</div>
                <div className="actum-szr-zas-content actum-szr-zas-content-alt-gen" />
                <div className="actum-szr-nap">
                  <label className="actum-szr-nap-label">Napięcie L1-N</label>
                  <input
                    id="nap"
                    type="text"
                    name="nap"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                  <label className="actum-szr-nap-label">Napięcie L2-N</label>
                  <input type="text" name="nap" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">Napięcie L3-N</label>
                  <input
                    type="text"
                    name="nap"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                  <label className="actum-szr-nap-label">Napięcie L1-L2</label>
                  <input
                    type="text"
                    name="nap"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                  <label className="actum-szr-nap-label">Napięcie L2-L3</label>
                  <input
                    type="text"
                    name="nap"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                  <label className="actum-szr-nap-label">Napięcie L3-L1</label>
                  <input
                    type="text"
                    name="nap"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                  <div
                    className="actum-szr-button-container actum-szr-padding-bottom-5px"
                  >
                    <div
                      className="actum-szr-indicator-button actum-szr-red-gradient"
                    />
                      Zasilanie ok
                    <div />
                  </div>
                </div>
                <div className="actum-szr-asym">
                  <label className="actum-szr-nap-label">Napięcie</label>
                  <input type="text" name="percent" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">Prąd</label>
                  <input
                    type="text"
                    name="percent"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                </div>
                <div className="actum-szr-wsp-title">Współczynnik mocy</div>
                <div className="actum-szr-wsp">
                  <label className="actum-szr-nap-label">cos L1</label>
                  <input type="text" name="wsp" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">cos L2</label>
                  <input type="text" name="wsp" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">cos L3</label>
                  <input
                    type="text"
                    name="wsp"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                  <label className="actum-szr-nap-label">cos Total</label>
                  <input
                    type="text"
                    name="wsp"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                </div>
                <div className="actum-szr-pr-title">Prąd</div>
                <div className="actum-szr-pr">
                  <label className="actum-szr-nap-label">Prąd L1</label>
                  <input type="text" name="pr" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">Prąd L2</label>
                  <input type="text" name="pr" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">Prąd L3</label>
                  <input
                    type="text"
                    name="pr"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                </div>
                <div className="actum-szr-moc-title">Moc</div>
                <div className="actum-szr-moc">
                  <label className="actum-szr-nap-label">Moc czynna</label>
                  <input type="text" name="m_1" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">Moc bierna</label>
                  <input type="text" name="m_2" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">Moc pozorna</label>
                  <input
                    type="text"
                    name="m_3"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                </div>
                <div className="actum-szr-thdnap-title">THD Napięcie</div>
                <div className="actum-szr-thdnap">
                  <label className="actum-szr-nap-label">THD Napięcie L1</label>
                  <input type="text" name="percent" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">THD Napięcie L2</label>
                  <input
                    type="text"
                    name="percent"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                  <label className="actum-szr-nap-label">THD Napięcie L3</label>
                  <input
                    type="text"
                    name="percent"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                </div>
                <div className="actum-szr-thdprad-title">THD Prąd</div>
                <div className="actum-szr-thdprad">
                  <label className="actum-szr-nap-label">THD Prąd L1</label>
                  <input type="text" name="percent" placeholder="" className="actum-szr-param-input" />
                  <label className="actum-szr-nap-label">THD Prąd L2</label>
                  <input
                    type="text"
                    name="percent"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                  <label className="actum-szr-nap-label">THD Prąd L3</label>
                  <input
                    type="text"
                    name="percent"
                    placeholder=""
                    className="actum-szr-param-input"
                  />
                </div>
                <div className="actum-szr-footer" />
              </div>
            </div>
          </>
        );
      case 'aparat':
        return (
          <>
            {menu}
            <div className="actum-szr-content actum-szr-content-tall">
              <div className="actum-szr-aparat-rows ">
                <div className="actum-szr-title">Zasilanie GEN</div>
                <div className="actum-szr-title">Diagnostyka wyłącznika</div>

                <div className="actum-szr-zas-content actum-szr-zas-content-alt-gen" />

                <div className="actum-szr-stats">
                  <div className="actum-szr-aparat2-rows">
                    <div className="actum-szr-diodes">
                      <div className="actum-szr-button-container">
                        <div className="actum-szr-indicator-button actum-szr-red-gradient" />
                          Q3 Załączony
                        <div />
                      </div>
                      <div className="actum-szr-button-container">
                        <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                          Q3 Wyłączony
                        <div />
                      </div>
                      <div className="actum-szr-button-container">
                        <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                          Q3 Wyzwolony
                        <div />
                      </div>
                      <div className="actum-szr-button-container">
                        <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                          Awaria załączania Q3
                        <div />
                      </div>
                      <div className="actum-szr-button-container">
                        <div className="actum-szr-indicator-button actum-szr-red-gradient" />
                          Awaria wyłączania Q3
                        <div />
                      </div>
                      <div className="actum-szr-button-container">
                        <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                          Q3 wysunięty
                        <div />
                      </div>
                    </div>
                    <div className="actum-szr-title">Statystyka wyłącznika</div>
                    <div className="actum-szr-stats-subgrid">
                      <div className="actum-szr-stats-subsection">
                        <label className="actum-szr-nap-label">Liczba załączeń Q3</label>
                        <div className="actum-szr-reason-container">
                          <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                          <input
                            type="text"
                            placeholder="12"
                            className="actum-szr-param-input actum-szr-reason"
                          />
                        </div>
                        <label className="actum-szr-nap-label">Ostatnie załączenie</label>
                        <input
                          type="text"
                          placeholder="12:10:31 05/08/2019"
                          className="actum-szr-param-input actum-szr-reason"
                        />
                      </div>
                      <div className="actum-szr-stats-subsection">
                        <label className="actum-szr-nap-label">Liczba wyłączeń Q3</label>
                        <div className="actum-szr-reason-container">
                          <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                          <input
                            type="text"
                            placeholder="36"
                            className="actum-szr-param-input actum-szr-reason"
                          />
                        </div>
                        <label className="actum-szr-nap-label">Ostatnie wyłączenie</label>
                        <input
                          type="text"
                          placeholder="12:10:31 05/08/2019"
                          className="actum-szr-param-input actum-szr-reason"
                        />
                      </div>
                      <div className="actum-szr-stats-subsection">
                        <label className="actum-szr-nap-label">Liczba wyzwoleń Q3</label>
                        <div className="actum-szr-reason-container">
                          <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                          <input
                            type="text"
                            placeholder="12"
                            className="actum-szr-param-input actum-szr-reason"
                          />
                        </div>
                        <label className="actum-szr-nap-label">Ostatnie wyzwolenie</label>
                        <input
                          type="text"
                          placeholder="12:10:31 05/08/2019"
                          className="actum-szr-param-input actum-szr-reason"
                        />
                      </div>
                      <div className="actum-szr-stats-subsection">
                        <label className="actum-szr-nap-label">Liczba wysunięć Q3</label>
                        <div className="actum-szr-reason-container">
                          <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                          <input
                            type="text"
                            placeholder="73"
                            className="actum-szr-param-input actum-szr-reason"
                          />
                        </div>
                        <label className="actum-szr-nap-label">Ostatnie wysunięcie</label>
                        <input
                          type="text"
                          placeholder="12:10:31 05/08/2019"
                          className="actum-szr-param-input actum-szr-reason"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="actum-szr-footer" />
              </div>
            </div>
          </>
        );
      case 'zasilanie':
        return (
          <>
            {menu}
            <div className="actum-szr-content actum-szr-content-tall">
              <div className="actum-szr-aparat-rows">
                <div className="actum-szr-title">Zasilanie GEN</div>
                <div className="actum-szr-title">Statystyka zasilania GEN</div>

                <div className="actum-szr-zas-content actum-szr-zas-content-alt-gen" />

                <div className="actum-szr-stats actum-shr-schrunk">
                  <div className="actum-szr-stats-subsection">
                    <label className="actum-szr-nap-label">Liczba zaników GEN</label>
                    <div className="actum-szr-reason-container">
                      <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                      <input type="text" placeholder="12" className="actum-szr-param-input actum-szr-reason" />
                    </div>
                    <label className="actum-szr-nap-label">Ostatni zanik</label>
                    <input
                      type="text"
                      placeholder="12:10:31 05/08/2019"
                      className="actum-szr-param-input actum-szr-reason"
                    />
                  </div>
                  <div className="actum-szr-stats-subsection">
                    <label className="actum-szr-nap-label">Liczba powrotów GEN</label>
                    <div className="actum-szr-reason-container">
                      <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                      <input type="text" placeholder="36" className="actum-szr-param-input actum-szr-reason" />
                    </div>
                    <label className="actum-szr-nap-label">Ostatni powrót</label>
                    <input
                      type="text"
                      placeholder="12:10:31 05/08/2019"
                      className="actum-szr-param-input actum-szr-reason"
                    />
                  </div>
                  <div className="actum-szr-stats-subsection">
                    <label className="actum-szr-nap-label">Maks. moc czynna</label>
                    <div className="actum-szr-reason-container">
                      <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                      <input type="text" placeholder="392,92kW" className="actum-szr-param-input actum-szr-reason" />
                    </div>
                    <label className="actum-szr-nap-label">Data maks. moc czynn.</label>
                    <input
                      type="text"
                      placeholder="12:10:31 05/08/2019"
                      className="actum-szr-param-input actum-szr-reason"
                    />
                  </div>
                  <div className="actum-szr-stats-subsection">
                    <label className="actum-szr-nap-label">Min. moc czynna</label>
                    <div className="actum-szr-reason-container">
                      <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                      <input type="text" placeholder="314,32kW" className="actum-szr-param-input actum-szr-reason" />
                    </div>
                    <label className="actum-szr-nap-label">Data min. moc czynn.</label>
                    <input
                      type="text"
                      placeholder="12:10:31 05/08/2019"
                      className="actum-szr-param-input actum-szr-reason"
                    />
                  </div>
                  <div className="actum-szr-stats-subsection">
                    <label className="actum-szr-nap-label">Maks. moc bierna</label>
                    <div className="actum-szr-reason-container">
                      <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                      <input type="text" placeholder="100,11kVar" className="actum-szr-param-input actum-szr-reason" />
                    </div>
                    <label className="actum-szr-nap-label">Data maks. moc bierna</label>
                    <input
                      type="text"
                      placeholder="12:10:31 05/08/2019"
                      className="actum-szr-param-input actum-szr-reason"
                    />
                  </div>
                  <div className="actum-szr-stats-subsection">
                    <label className="actum-szr-nap-label">Min. moc bierna</label>
                    <div className="actum-szr-reason-container">
                      <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                      <input type="text" placeholder="41,82kVar" className="actum-szr-param-input actum-szr-reason" />
                    </div>
                    <label className="actum-szr-nap-label">Data min moc bierna</label>
                    <input
                      type="text"
                      placeholder="12:10:31 05/08/2019"
                      className="actum-szr-param-input actum-szr-reason"
                    />
                  </div>
                  <div className="actum-szr-stats-subsection">
                    <label className="actum-szr-nap-label">Maksymalny prąd</label>
                    <div className="actum-szr-reason-container">
                      <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                      <input type="text" placeholder="920,34A" className="actum-szr-param-input actum-szr-reason" />
                    </div>
                    <label className="actum-szr-nap-label">Data maks prądu</label>
                    <input
                      type="text"
                      placeholder="12:10:31 05/08/2019"
                      className="actum-szr-param-input actum-szr-reason"
                    />
                  </div>
                </div>
                <div className="actum-szr-footer" />
              </div>
            </div>
          </>
        );
      case 'statystyka':
        return (
          <>
            {menu}
            <div className="actum-szr-content actum-szr-content-tall">
              <div className="actum-szr-aparat-rows">
                <div className="actum-szr-title">Zasilanie GEN</div>
                <div className="actum-szr-title">Statystyka zasilania GEN</div>

                <div className="actum-szr-zas-content actum-szr-zas-content-alt-gen" />

                <div className="actum-szr-stats actum-shr-schrunk">


                  <div className="actum-szr-stat-gen-diodes">
                    <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                    <div className="actum-szr-button-container actum-szr-padding-bottom-5px">
                      <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                        Awaria zał. stycznika startu generatora
                      <div />
                    </div>
                  </div>
                  <div className="actum-szr-stat-gen-diodes">
                    <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                    <div className="actum-szr-button-container actum-szr-padding-bottom-5px">
                      <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                        Awaria wył. stycznika startu generatora
                      <div />
                    </div>
                  </div>
                  <div className="actum-szr-stat-gen-diodes">
                    <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                    <div className="actum-szr-button-container actum-szr-padding-bottom-5px">
                      <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                        Awaria startu generatora
                      <div />
                    </div>
                  </div>
                  <div className="actum-szr-stat-gen-diodes">
                    <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                    <div className="actum-szr-button-container actum-szr-padding-bottom-5px">
                      <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                        Awaria stopu generatora
                      <div />
                    </div>
                  </div>

                  <div className="actum-szr-stats-subsection">
                    <label className="actum-szr-nap-label">Licznik czasu pracy generatora</label>
                    <div className="actum-szr-reason-container">
                      <div className="actum-szr-accept-button actum-szr-gradient">Reset</div>
                      <input type="text" placeholder="1h 15min" className="actum-szr-param-input actum-szr-reason" />
                    </div>


                  </div>
                  <div className="actum-szr-footer" />
                </div>
              </div>
            </div>
          </>
        );
      case 'mocCzynnaPobrana':
        return (
          <>
            {menu}
            <div className="actum-szr-content actum-szr-content-tall">
              <div className="actum-szr-chart-data-wykres">
                <div className="actum-szr-first-title-wykres">Moc czynna pobrana 15-minutowa</div>
                <div className="actum-szr-chart-wykres">
                  <WykresYAxis
                    id="yAxis"
                    className="actum-szr-y-axis"
                    height="100%"
                  />
                  {/* <object */}
                  {/*  data="../assets/wykres-y-axis.svg" */}
                  {/*  type="image/svg+xml" */}
                  {/*  id="yAxis" */}
                  {/*  className="actum-szr-y-axis" */}
                  {/* /> */}
                  <div className="actum-szr-chart-wrapper-wykres">
                    <WykresZero
                      id="czynna"
                      className="actum-szr-czynna-svg actum-szr-15min-svg"
                    />
                    {/* <object */}
                    {/*  data="../assets/wykres_zero.svg" */}
                    {/*  type="image/svg+xml" */}
                    {/*  id="czynna" */}
                    {/*  className="actum-szr-czynna-svg actum-szr-15min-svg" */}
                    {/* /> */}
                  </div>
                </div>
                <div className="actum-szr-second-title-wykres">Wartości maksymalne / minimalne</div>
                <div className="actum-szr-data">
                  <div className="actum-szr-data-text-left">Maks. moc 15-min</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="00000.0kW"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-data-text">Data</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="12/18/2017 1:22:34 PM"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-input-container">
                    <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                  </div>

                  <div className="actum-szr-data-text-left">Min. moc 15-min</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="00000.0kW"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-data-text">Data</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="10/22/2017 7:58:11 AM"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-input-container">
                    <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'mocCzynnaOddana':
        return (
          <>
            {menu}
            <div className="actum-szr-content actum-szr-content-tall">
              <div className="actum-szr-chart-data-wykres">
                <div className="actum-szr-first-title-wykres">Moc czynna oddana 15-minutowa</div>
                <div className="actum-szr-chart-wykres">
                  <WykresYAxis
                    id="yAxis"
                    className="actum-szr-y-axis"
                    height="100%"
                  />
                  {/* <object */}
                  {/*  data="../assets/wykres-y-axis.svg" */}
                  {/*  type="image/svg+xml" */}
                  {/*  id="yAxis" */}
                  {/*  className="actum-szr-y-axis" */}
                  {/* /> */}
                  <div className="actum-szr-chart-wrapper-wykres">
                    <WykresZero
                      id="czynna"
                      className="actum-szr-czynna-svg actum-szr-15min-svg"
                    />
                    {/* <object */}
                    {/*  data="../assets/wykres_zero.svg" */}
                    {/*  type="image/svg+xml" */}
                    {/*  id="czynna" */}
                    {/*  className="actum-szr-czynna-svg actum-szr-15min-svg" */}
                    {/* /> */}
                  </div>
                </div>
                <div className="actum-szr-second-title-wykres">Wartości maksymalne / minimalne</div>
                <div className="actum-szr-data">
                  <div className="actum-szr-data-text-left">Maks. moc 15-min</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="00000.0kW"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-data-text">Data</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="12/18/2017 1:22:34 PM"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-input-container">
                    <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                  </div>

                  <div className="actum-szr-data-text-left">Min. moc 15-min</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="00000.0kW"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-data-text">Data</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="10/22/2017 7:58:11 AM"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-input-container">
                    <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'mocBiernaPobrana':
        return (
          <>
            {menu}
            <div className="actum-szr-content actum-szr-content-tall">
              <div className="actum-szr-chart-data-wykres">
                <div className="actum-szr-first-title-wykres">Moc bierna indukcyjna 15-minutowa</div>
                <div className="actum-szr-chart-wykres">
                  <WykresYAxis
                    id="yAxis"
                    className="actum-szr-y-axis"
                    height="100%"
                  />
                  {/* <object */}
                  {/*  data="../assets/wykres-y-axis.svg" */}
                  {/*  type="image/svg+xml" */}
                  {/*  id="yAxis" */}
                  {/*  className="actum-szr-y-axis" */}
                  {/* /> */}
                  <div className="actum-szr-chart-wrapper-wykres">
                    <WykresZero
                      id="czynna"
                      className="actum-szr-czynna-svg actum-szr-15min-svg"
                    />
                    {/* <object */}
                    {/*  data="../assets/wykres_zero.svg" */}
                    {/*  type="image/svg+xml" */}
                    {/*  id="czynna" */}
                    {/*  className="actum-szr-czynna-svg actum-szr-15min-svg" */}
                    {/* /> */}
                  </div>
                </div>
                <div className="actum-szr-second-title-wykres">Wartości maksymalne / minimalne</div>
                <div className="actum-szr-data">
                  <div className="actum-szr-data-text-left">Maks. moc 15-min</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="00000.0kW"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-data-text">Data</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="12/18/2017 1:22:34 PM"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-input-container">
                    <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                  </div>

                  <div className="actum-szr-data-text-left">Min. moc 15-min</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="00000.0kW"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-data-text">Data</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="10/22/2017 7:58:11 AM"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-input-container">
                    <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'mocBiernaOddana':
        return (
          <>
            {menu}
            <div className="actum-szr-content actum-szr-content-tall">
              <div className="actum-szr-chart-data-wykres">
                <div className="actum-szr-first-title-wykres">Moc bierna pojemnościowa 15-minutowa</div>
                <div className="actum-szr-chart-wykres">
                  <WykresYAxis
                    id="yAxis"
                    className="actum-szr-y-axis"
                    height="100%"
                  />
                  <div className="actum-szr-chart-wrapper-wykres">
                    <WykresZero
                      id="czynna"
                      className="actum-szr-czynna-svg actum-szr-15min-svg"
                    />
                  </div>
                </div>
                <div className="actum-szr-second-title-wykres">Wartości maksymalne / minimalne</div>
                <div className="actum-szr-data">
                  <div className="actum-szr-data-text-left">Maks. moc 15-min</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="00000.0kW"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-data-text">Data</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="12/18/2017 1:22:34 PM"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-input-container">
                    <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                  </div>

                  <div className="actum-szr-data-text-left">Min. moc 15-min</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="00000.0kW"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-data-text">Data</div>
                  <div className="actum-szr-input-container">
                    <input
                      type="text"
                      placeholder="10/22/2017 7:58:11 AM"
                      className="actum-szr-data-input"
                    />
                  </div>
                  <div className="actum-szr-input-container">
                    <div className="actum-szr-reset-button actum-szr-gradient">Reset</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'napiecieN':
        return (
          <>
            {menu}
            <div className="actum-szr-content">
              <div className="actum-szr-chart-data-prad">
                <div className="actum-szr-first-title-wykres">Napięcie</div>
                <div className="actum-szr-chart-wykres">
                  <WykresYAxis
                    id="yAxis"
                    className="actum-szr-y-axis"
                    height="100%"
                  />
                  <div className="actum-szr-chart-wrapper-wykres">
                    <WykresZero
                      id="czynna"
                      className="actum-szr-czynna-svg"
                    />
                  </div>
                </div>
                <div className="actum-szr-data-prad">
                  <div className="actum-szr-button-container actum-szr-prad-text">
                    <div className="actum-szr-prad-button actum-szr-green-gradient" />
                      Napięcie L1-N
                    <div />
                  </div>
                  <div className="actum-szr-button-container actum-szr-prad-text">
                    <div className="actum-szr-prad-button actum-szr-red-gradient" />
                      Napięcie L2-N
                    <div />
                  </div>
                  <div className="actum-szr-button-container actum-szr-prad-text">
                    <div className="actum-szr-prad-button actum-szr-button-blue" />
                      Napięcie L3-N
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'napiecieL':
        return (
          <>
            {menu}
            <div className="actum-szr-content">
              <div className="actum-szr-chart-data-prad">
                <div className="actum-szr-first-title-wykres">Napięcie</div>
                <div className="actum-szr-chart-wykres">
                  <WykresYAxis
                    id="yAxis"
                    className="actum-szr-y-axis"
                    height="100%"
                  />
                  <div className="actum-szr-chart-wrapper-wykres">
                    <WykresZero
                      id="czynna"
                      className="actum-szr-czynna-svg"
                    />
                  </div>
                </div>
                <div className="actum-szr-data-prad">
                  <div className="actum-szr-button-container actum-szr-prad-text">
                    <div className="actum-szr-prad-button actum-szr-green-gradient" />
                      Napięcie L1-L
                    <div />
                  </div>
                  <div className="actum-szr-button-container actum-szr-prad-text">
                    <div className="actum-szr-prad-button actum-szr-red-gradient" />
                      Napięcie L2-L
                    <div />
                  </div>
                  <div className="actum-szr-button-container actum-szr-prad-text">
                    <div className="actum-szr-prad-button actum-szr-button-blue" />
                      Napięcie L3-L
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'prad':
        return (
          <>
            {menu}
            <div className="actum-szr-content">
              <div className="actum-szr-chart-data-prad">
                <div className="actum-szr-first-title-wykres">Moc bierna pojemnościowa 15-minutowa</div>
                <div className="actum-szr-chart-wykres">
                  <WykresYAxis
                    id="yAxis"
                    className="actum-szr-y-axis"
                    height="100%"
                  />
                  <div className="actum-szr-chart-wrapper-wykres">
                    <WykresZero
                      id="czynna"
                      className="actum-szr-czynna-svg"
                    />
                  </div>
                </div>
                <div className="actum-szr-data-prad">
                  <div className="actum-szr-button-container actum-szr-prad-text">
                    <div className="actum-szr-prad-button actum-szr-green-gradient" />
                      Prąd L1
                    <div />
                  </div>
                  <div className="actum-szr-button-container actum-szr-prad-text">
                    <div className="actum-szr-prad-button actum-szr-red-gradient" />
                      Prąd L2
                    <div />
                  </div>
                  <div className="actum-szr-button-container actum-szr-prad-text">
                    <div className="actum-szr-prad-button actum-szr-gradient" />
                      Prąd L3
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'zdarzenia':
        return (
          <>
            {menu}
            <div className="actum-szr-events-content actum-szr-content-tall">
              <div className="actum-szr-events-title">Czas</div>
              <div className="actum-szr-events-title">Data</div>
              <div className="actum-szr-events-title">Stan</div>
              <div className="actum-szr-events-title">Treść</div>

              <span className="actum-szr-clicked">12:52:34 PM</span>
              <span className="actum-szr-clicked">6/5/2018</span>
              <span className="actum-szr-clicked">I</span>
              <span className="actum-szr-clicked">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:17 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:43:18 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>


              <span className="actum-szr-events-element">3:43:18 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">3:43:08 AM</span>
              <span className="actum-szr-events-element">6/5/2018</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">3:43:06 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">3:43:08 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:06:27 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:06:26 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:06:25 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:06:24 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:00:23 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:00:23 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:17 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:17 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:43:18 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>


              <span className="actum-szr-events-element">3:43:18 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">3:43:08 AM</span>
              <span className="actum-szr-events-element">6/5/2018</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">3:43:06 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">3:43:08 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:06:27 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:06:26 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:06:25 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:06:24 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:00:23 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:00:23 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:17 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:17 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:43:18 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>


              <span className="actum-szr-events-element">3:43:18 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">3:43:08 AM</span>
              <span className="actum-szr-events-element">6/5/2018</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">3:43:06 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">3:43:08 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:06:27 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:06:26 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:06:25 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:06:24 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:00:23 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:00:23 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:17 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:17 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:43:18 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>


              <span className="actum-szr-events-element">3:43:18 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">3:43:08 AM</span>
              <span className="actum-szr-events-element">6/5/2018</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">3:43:06 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">3:43:08 AM</span>
              <span className="actum-szr-events-element">4/25/2018</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:06:27 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:06:26 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:06:25 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:06:24 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:00:23 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Wyzwolony</span>

              <span className="actum-szr-events-element">1:00:23 PM</span>
              <span className="actum-szr-events-element">10/24/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:17 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:17 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">GEN Załączony</span>

              <span className="actum-szr-events-element">1:16:08 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">1:05:46 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">I</span>
              <span className="actum-szr-events-element">Brak komunikacji z GEN - JVA</span>

              <span className="actum-szr-events-element">12:36:24 PM</span>
              <span className="actum-szr-events-element">10/17/2017</span>
              <span className="actum-szr-events-element">(I)O</span>
              <span className="actum-szr-events-element">GEN Załączony</span>
            </div>
          </>
        );
    }
  }
}

export default ParametryGen;
