import React, { Component } from 'react';
import { SiemensPlansza1 } from '../../../assets';

class Schemat extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
        updateData2();
    `;

    document.body.appendChild(script);


    const Transformers = document.getElementsByClassName('redirectTR');
    for (let j = 0; j < Transformers.length; j += 1) {
      Transformers[j].addEventListener('click', () => {
        let url = '/schemat/parametry';
        if (Transformers[j].getAttribute('data-element-name')) {
          url += `?elementName=${Transformers[j].getAttribute('data-element-name')}`;
        }
        this.props.history.push(url);
      });
    }

    const Switches = document.getElementsByClassName('redirectSwitch');
    for (let i = 0; i < Switches.length; i += 1) {
      Switches[i].addEventListener('click', () => {
        let url = '/schemat/second-params';
        if (Switches[i].getAttribute('data-element-name')) {
          url += `?elementName=${Switches[i].getAttribute('data-element-name')}`;
        }
        this.props.history.push(url);
      });
    }

    const Gen = document.getElementsByClassName('redirectGen');
    for (let k = 0; k < Gen.length; k += 1) {
      Gen[k].addEventListener('click', () => {
        let url = '../schemat/parametry-gen';
        if (Gen[k].getAttribute('data-element-name')) {
          url += `?elementName=${Gen[k].getAttribute('data-element-name')}`;
        }
        this.props.history.push(url);
      });
    }
  }

  render() {
    return (
      <>
        <div className="actum-szr-content actum-szr-content-small">
          <div className="actum-szr-szr-mode">
              Tryb SZR:
            <span className="actum-szr-szr-button actum-szr-light-gradient">Tryb auto</span>
          </div>
          <SiemensPlansza1
            id="plansza"
            className="actum-szr-plansza"
          >
              planszaW
          </SiemensPlansza1>
        </div>
      </>
    );
  }
}

export default Schemat;
