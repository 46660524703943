export function swapDataGraph(timeStepSeconds = 14, yStep = 10, yStartingValue = 0) {
  swapYAxis(yStep, yStartingValue);
  swapXAxis(timeStepSeconds);
}

function swapXAxis(timeStepSeconds) {
  // taking first switch as an example one
  const date = new Date();
  const timesArrayInitiator = [-4, -3, -2, -1, 0, 1, 2, 3];
  const timesArray = timesArrayInitiator.map((time) => {
    const newDateObj = new Date(date.getTime() + time * timeStepSeconds * 1000);
    let h = newDateObj.getHours(); // 0 - 23
    let m = newDateObj.getMinutes(); // 0 - 59
    let s = newDateObj.getSeconds(); // 0 - 59
    let session = 'AM';

    if (h === 0) {
      h = 12;
    }

    if (h > 12) {
      h -= 12;
      session = 'PM';
    }

    h = (h < 10) ? `0${h}` : h;
    m = (m < 10) ? `0${m}` : m;
    s = (s < 10) ? `0${s}` : s;

    return `${h}:${m}:${s} ${session}`;
  });

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const calendar = `${day}/${month}/${year}`;

  function listenerHelper() {
    const dates = document.getElementsByClassName('date');
    for (let i = 0; i < dates.length; i += 1) {
      dates[i].innerHTML = calendar;
    }
    const times = document.getElementsByClassName('time');
    for (let k = 0; k < times.length; k += 1) {
      times[k].innerHTML = timesArray[k];
    }
  }

  if (document.getElementById('czynna')
      && document.getElementsByClassName('time').length
      && document.getElementsByClassName('date').length) {
    listenerHelper();
  } else {
    document.getElementById('czynna').addEventListener('load', () => {
      listenerHelper();
    });
  }
}

function swapYAxis(step, startingValue) {
  // if diagram is already loaded and we need to set data again
  if (document.getElementById('yAxis') && document.getElementsByClassName('yAxisText').length) {
    const values = document.getElementsByClassName('yAxisText');
    for (let i = 0; i < values.length; i += 1) {
      values[i].innerHTML = startingValue + step * i;
    }
  } else {
    // else wait for load event
    document.getElementById('yAxis').addEventListener('load', () => {
      const values = document.getElementsByClassName('yAxisText');
      for (let i = 0; i < values.length; i += 1) {
        values[i].innerHTML = startingValue + step * i;
      }
    });
  }
}
