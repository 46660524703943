import React, { Component } from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import classNames from 'classnames';
import SzrContent from './content-szr';

class SchemaSzr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 'schemat',
    };
    const { location } = this.props;
    if (location.state && location.state.redirect) {
      this.state = {
        content: 'diagnostyka',
      };
    }
  }

    setContent = (content) => {
      this.setState({ content });
    };

    render() {
      const { location } = this.props;
      const { content } = this.state;
      return (
        <>
          <div className="actum-szr-params-container">
            <div className="actum-szr-sidebar actum-szr-content-tall">
              <div className="actum-szr-sidebar-rows">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => { this.setContent('schemat'); }}
                  className={classNames({
                    'actum-szr-param-active actum-szr-yellow-gradient': content === 'schemat',
                    'actum-szr-param-button actum-szr-gradient': content !== 'schemat',
                  })}
                >
Schemat
                          SZR
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => { this.setContent('zdarzenia'); }}
                  className={classNames({
                    'actum-szr-param-active actum-szr-yellow-gradient': content === 'zdarzenia',
                    'actum-szr-param-button actum-szr-gradient': content !== 'zdarzenia',
                  })}
                >
Zdarzenia
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => { this.setContent('diagnostyka'); }}
                  className={classNames({
                    'actum-szr-param-active actum-szr-yellow-gradient': content === 'diagnostyka',
                    'actum-szr-param-button actum-szr-gradient': content !== 'diagnostyka',
                  })}
                >
Diagnostyka
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => { this.setContent('ustawienia'); }}
                  className={classNames({
                    'actum-szr-param-active actum-szr-yellow-gradient': content === 'ustawienia',
                    'actum-szr-param-button actum-szr-gradient': content !== 'ustawienia',
                  })}
                >
Ustawienia
                </div>
              </div>
              <div className="actum-szr-sidebar-bottom-buttons">
                <Link to="/dashboard" className="actum-szr-param-bottom actum-szr-gradient">
                  <img src="assets/siemens-home.svg" alt="home" className="actum-szr-footer-icon" />
                </Link>
                <Link
                  to={`/${this.props.history.location.pathname.split('/')[1]}-main`}
                  role="button"
                  tabIndex={0}
                  className="actum-szr-param-bottom actum-szr-gradient"
                >
                  <img src="assets/siemens-Asset_10.svg" alt="home" className="actum-szr-footer-icon" />
                </Link>
              </div>
            </div>
            <div className={classNames({
              'actum-szr-content': content !== 'zdarzenia',
              'actum-szr-content-tall': true,
              'actum-szr-events-content': content === 'zdarzenia',
            })}
            >
              <SzrContent
                content={content}
                redirect={location.state ? location.state.redirect : false}
              />
            </div>
          </div>
        </>
      );
    }
}

export default SchemaSzr;
