import React, { Component } from 'react';

class Dokumentacja extends Component {
  render() {
    return (
      <>
        <div className="actum-szr-empty-data-container" style={{ overflow: 'auto' }}>
          {/* <embed src="assets/SZR_NHQ_R4.pdf" width="100%" height="634px" /> */}
          <img src="assets/SZR_NHQ_R4.jpg" alt="SZR_NHO_R4" width="100%" />
        </div>
      </>
    );
  }
}

export default Dokumentacja;
