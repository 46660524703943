import React, { Component } from 'react';

class Elewacja extends Component {
  render() {
    return (
        <div className="actum-szr-elewacja-container">

          <img src="assets/rozdzielnica.png" useMap="#image-map" />
          <map name="image-map">
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=B1B_P2"
                coords="35,89,158,127"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B1B_P2'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=B2_RG"
                coords="35,137,159,178"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B2_RG'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=B2_R1"
                coords="35,187,159,228"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B2_R1'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=B1A"
                coords="35,238,158,278"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B1A'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=Parking"
                coords="36,284,159,329"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=Parking'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                onClick={() => (this.props.history.push('/elewacja/parametry?elementName=Trafo_TR1'))}
                style={{ cursor: 'pointer' }}
                coords="251,238,335,429"
                shape="rect"
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/aparat-zabezpieczajacy2.html?elementName=Trafo_TR1"
                onClick={() => (this.props.history.push('/elewacja/parametry?elementName=Trafo_TR1', {newContent: 'aparat'}))}
                style={{ cursor: 'pointer' }}
                coords="337,237,425,428"
                shape="rect"
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/aparat-zabezpieczajacy2.html?elementName=Trafo_TR2"
                onClick={() => (this.props.history.push('/elewacja/parametry?elementName=Trafo_TR2', {newContent: 'aparat'}))}
                style={{ cursor: 'pointer' }}
                coords="551,237,640,429"
                shape="rect"
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=B3_komp."
                coords="643,88,765,129"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B3_komp.'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=B1A_komp."
                coords="642,137,765,178"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B1A_komp.'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=B1A_klim."
                coords="643,188,766,228"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B1A_klim.'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=R3_RG"
                coords="643,238,766,278"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=R3_RG'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=R2_RG"
                coords="643,289,766,328"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=R2_RG'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=B1B_P2_2"
                coords="643,337,766,378"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B1B_P2_2'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                onClick={() => (this.props.history.push('/elewacja/parametry?elementName=Trafo_TR2'))}
                style={{ cursor: 'pointer' }}
                coords="857,238,945,429"
                shape="rect"
            />
            <area
                target=""
                alt=""
                title=""
                coords="961,173,1006,248"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/parametry-gen'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                // href="elewacja/secondparams.html?elementName=B2_serwer"
                coords="947,337,1071,379"
                shape="rect"
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B2_serwer'))}
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                onClick={() => (this.props.history.push('/elewacja/second-params?elementName=B01_MSG'))}
                // href="elewacja/secondparams.html?elementName=B01_MSG"
                coords="947,388,1071,428"
                shape="rect"
                style={{ cursor: 'pointer' }}
            />
            <area
                target=""
                alt=""
                title=""
                onClick={() => (this.props.history.push('/schema-szr-main', { redirect: true }))}
                coords="439,123,538,197"
                shape="rect"
                style={{ cursor: 'pointer' }}
            />
          </map>
        </div>
    );
  }
}

export default Elewacja;
