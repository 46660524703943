import React, { Component } from 'react';
import {
  Link, Redirect, Route, Switch, 
} from 'react-router-dom';
import Dashboard from './dashboard';
import Elewacja from './elewacja/elewacja';
import Schemat from './schemat/schemat';
import Zdarzenia from './zdarzenia';
import Siec from './siec';
import Dokumentacja from './dokumentacja';

class MainScreen extends Component {
  render() {
    return (
      <>
        <div className="actum-szr-footer">
          <div className="actum-szr-footer-row">
            <Link to="/dashboard" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-home.svg" alt="home" className="actum-szr-footer-icon" />
            </Link>
            <Link to="elewacja-main" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-Asset_9.svg" alt="rozdzielnica" className="actum-szr-footer-icon" />
            </Link>
            <Link to="schemat-main" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-Asset_8.svg" alt="schemat" className="actum-szr-footer-icon" />
            </Link>
            <Link to="schema-szr-main" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-Asset_7.svg" alt="szr" className="actum-szr-footer-icon" />
            </Link>
            <Link to="zdarzenia-main" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-mail.svg" alt="zdarzenia" className="actum-szr-footer-icon" />
            </Link>
            <Link to="diagnostyka-main" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-Asset_16.svg" alt="diagnostyka" className="actum-szr-footer-icon" />
            </Link>
            <Link to="dokumentacja-main" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-docs.svg" alt="dokumentacja" className="actum-szr-footer-icon" />
            </Link>
            <Link to="siec-main" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-graph.svg" alt="graf" className="actum-szr-footer-icon" />
            </Link>
            <div />
            <Link to="/dashboard" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-team.svg" alt="team" className="actum-szr-footer-icon" />
            </Link>
            <Link to="/dashboard" className="actum-szr-footer-button actum-szr-gradient">
              <img src="assets/siemens-turn-off.svg" alt="turn-off" className="actum-szr-footer-icon" />
            </Link>
          </div>
        </div>
        <Switch>
          <Route
            path="/dashboard"
            component={Dashboard}
          />
          <Route
            path="/elewacja-main"
            component={Elewacja}
          />
          <Route
            path="/schemat-main"
            component={Schemat}
          />
          <Route
            path="/zdarzenia-main"
            component={Zdarzenia}
          />
          <Route
            path="/siec-main"
            component={Siec}
          />
          <Route
            path="/dokumentacja-main"
            component={Dokumentacja}
          />
          <Redirect to="/dashboard" />
        </Switch>
      </>
    );
  }
}

export default MainScreen;
