import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Siec extends Component {
  render() {
    return (
      <div className="actum-szr-siec-container">


        <img src="assets/orange1.png" className="actum-szr-orange1" />
        <img src="assets/orange2.png" className="actum-szr-orange2" />
        <img src="assets/orange3.png" className="actum-szr-orange3" />
        <img src="assets/orange4.png" className="actum-szr-orange4" />


        <div className="actum-szr-siec-head-container">
          <Link to="/siec/parametry?elementName=Trafo_TR2">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  4A1 - Analizator TR2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/analizator.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="schema-szr-main">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  5A3 - Sterownik SZR
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/sterownik.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/parametry?elementName=Trafo_TR1">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  6A1 - Analizator TR1
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/analizator.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/parametry-gen">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  10A1 - Analizator GEN
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/analizator.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <div className="actum-szr-siec-element actum-szr-cursor-unset">
            <div className="actum-szr-siec-element-header">
                5A6 - Sterownik RG
            </div>
            <div className="actum-szr-siec-element-content">
              <img src="assets/rg.png" alt="home" />
              <div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Komunikacja
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Błąd IO
                  <div />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="actum-szr-siec-green-bar" />
        <div className="actum-szr-siec-content-container">
          <div className="actum-szr-siec-element actum-szr-cursor-unset">
            <div className="actum-szr-siec-element-header">
                2A2 - COM800
            </div>
            <div className="actum-szr-siec-element-content">
              <img src="assets/com.png" alt="home" />
              <div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Komunikacja
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Błąd IO
                  <div />
                </div>
              </div>
            </div>
          </div>

          <Link to="siec/second-params?elementName=B1B_P2">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  2F1 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/second-params?elementName=B2_RG">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  2F2 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link to="siec/second-params?elementName=B2_R1">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  2F4 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link to="siec/second-params?elementName=B1A">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  2F5 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link to="siec/second-params?elementName=Parking">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  2F6 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <div className="actum-szr-siec-element actum-szr-cursor-unset">
            <div className="actum-szr-siec-element-header">
                4A2 - COM800
            </div>
            <div className="actum-szr-siec-element-content">
              <img src="assets/com.png" alt="home" />
              <div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Komunikacja
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Błąd IO
                  <div />
                </div>
              </div>
            </div>
          </div>

          <Link to="siec/second-params?elementName=B01_MSF_2">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  1FP1 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/second-params?elementName=RPZOK_2">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  1FP2 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/second-params?elementName=B01_MSF">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  2FP1 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/second-params?elementName=RPZOK">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  2FP2 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/second-params?elementName=B1B_P2_2">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  1F7 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>


          <div className="actum-szr-siec-element actum-szr-cursor-unset">
            <div className="actum-szr-siec-element-header">
                7A2 - COM800
            </div>
            <div className="actum-szr-siec-element-content">
              <img src="assets/com.png" alt="home" />
              <div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Komunikacja
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Błąd IO
                  <div />
                </div>
              </div>
            </div>
          </div>

          <Link to="siec/second-params?elementName=B01_MSB1">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  1F1 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/second-params?elementName=B3_komp.">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  1F2 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/second-params?elementName=B1A_komp.">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  1F3 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/second-params?elementName=R3_RG">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  1F5 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="siec/second-params?elementName=R2_RG">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  1F6 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <div className="actum-szr-siec-element actum-szr-cursor-unset">
            <div className="actum-szr-siec-element-header">
                11A2 - COM800
            </div>
            <div className="actum-szr-siec-element-content">
              <img src="assets/com.png" alt="home" />
              <div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Komunikacja
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Błąd IO
                  <div />
                </div>
              </div>
            </div>
          </div>

          <Link to="siec/second-params?elementName=B2_serwer">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  3F1 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link to="siec/second-params?elementName=B01_MSG">
            <div className="actum-szr-siec-element">
              <div className="actum-szr-siec-element-header">
                  3F2 - 3VA2
              </div>
              <div className="actum-szr-siec-element-content">
                <img src="assets/wyl.png" alt="home" />
                <div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Komunikacja
                    <div />
                  </div>
                  <div className="actum-szr-button-container">
                    <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                      Błąd IO
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </Link>


        </div>

        <div className="actum-szr-siec-last-element">
          <div className="actum-szr-siec-element actum-szr-cursor-unset">
            <div className="actum-szr-siec-element-header">
                5A5 - Panel HMI TP1200
            </div>
            <div className="actum-szr-siec-element-content">
              <img src="assets/panel.png" alt="home" />
              <div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Komunikacja
                  <div />
                </div>
                <div className="actum-szr-button-container">
                  <div className="actum-szr-indicator-button actum-szr-green-gradient" />
                    Błąd IO
                  <div />
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default Siec;
